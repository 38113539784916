import { properties } from "../properties";
import api from "../services/api";

function create(values) {
  return api.post(`${properties.adminService}/rules`, values);
}

function fetch(id) {
  return api.get(`${properties.adminService}/rules/${id}`);
}

function update(values) {
  return api.put(`${properties.adminService}/rules/${values.id}`, values);
}

function deleteRule(id) {
  return api.delete(`${properties.adminService}/rules/${id}`);
}

function fetchAllDictionaryRulesByCustomerId() {
  return api.get(`${properties.adminService}/rules/dictionary`);
}

function fetchAllDefaultDictionaryRules(pageSize, pageNumber, searchText) {
  return api.get(
    `${properties.adminService}/rules/dictionary?isHidden=true&pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`
  );
}

function fetchAllCustomDictionaryRulesByCustomerId(
  pageSize,
  pageNumber,
  searchText
) {
  return api.get(
    `${properties.adminService}/rules/dictionary?pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`
  );
}

function fetchAllClassificationDictionaryRules(classificationId) {
  return api.get(
    `${properties.adminService}/rules?classificationId=${classificationId}&isDictionaryRule=true`
  );
}

function fetchAllClassificationCustomRules(classificationId) {
  return api.get(
    `${properties.adminService}/rules?classificationId=${classificationId}&isDictionaryRule=false`
  );
}

export {
  create as createRule,
  fetch as fetchRule,
  fetchAllDictionaryRulesByCustomerId,
  fetchAllCustomDictionaryRulesByCustomerId,
  fetchAllDefaultDictionaryRules,
  update as updateRule,
  deleteRule,
  fetchAllClassificationDictionaryRules,
  fetchAllClassificationCustomRules
};
