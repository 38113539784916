import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Stack, Button, Divider } from "@mui/material";
import { Delete } from "@mui/icons-material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';

export default function FormFooter({
  formType,
  errors,
  touched,
  handleSubmit,
  showDeleteButton,
  handleDelete,
  deleteButtonText,
  handleClose
}) {
  return (
    <div className="form-footer">
      <Divider sx={{ opacity: 1 }} />
      <br />
      {errors
        ? Object.keys(errors).map((fieldName) => {
            if (touched[fieldName] && errors[fieldName]) {
              return (
                <div key={fieldName} className="error-text">
                  {errors[fieldName]}
                </div>
              );
            }
          })
        : null}

      <Stack spacing={2} direction="row" justifyContent="flex-end">
        {showDeleteButton ? (
          <Button
            variant="outlined"
            color="error"
            sx={{ marginRight: "auto !important" }}
            onClick={handleDelete}
            startIcon={<Delete />}
          >
            {deleteButtonText ? deleteButtonText : "Delete"}
          </Button>
        ) : null}
        <Button
          className="primary-button-outlined"
          variant="outlined"
          onClick={handleClose}
          startIcon={
            <CloseIcon />
          }
        >
          Cancel
        </Button>
        <Button
          className="primary-button-filled"
          variant="contained"
          type="submit"
          onClick={handleSubmit ? handleSubmit : null}
          startIcon={
            formType == "Update" ? <BeenhereOutlinedIcon /> : formType == "Add" ? <AddIcon /> : <TaskAltIcon />
          }
        >
          {formType}
        </Button>
      </Stack>
      <br />
    </div>
  );
}
