import { properties } from "../properties";
import api from "../services/api";

const controller = "reporting";

function getHighRiskEventCount() {
  return api.get(`${properties.reportingService}/high-risk/count`);
}

function getTopPoliciesBreached(count) {
  return api.get(
    `${properties.reportingService}/policies-breached/top?n=${count}`
  );
}

function getTopRulesBreached(count) {
  return api.get(
    `${properties.reportingService}/rules-breached/top?n=${count}`
  );
}

function getIncidentsPerDay(fromDate, toDate) {
  return api.get(
    `${properties.reportingService}/incidents/per-day?fromDate=${fromDate}&toDate=${toDate}`
  );
}

function getTopRiskyUsers(count) {
  return api.get(`${properties.reportingService}/risky-users/top?n=${count}`);
}

function getTopRiskyDevices(count) {
  return api.get(`${properties.reportingService}/risky-devices/top?n=${count}`);
}

function getDeviceReportingList(
  destinationId,
  pageSize,
  pageNumber,
  searchText
) {
  let url = `${properties.reportingService}?`;
  if (destinationId != null && destinationId != "all")
    url += `destinationId=${destinationId}&`;
  url += `pageSize=${pageSize}&pageNumber=${pageNumber}&reportType=DEVICE&searchText=${searchText}`;
  return api.get(url);
}

function getEmailReportingList(pageSize, pageNumber, searchText) {
  return api.get(
    `${properties.reportingService}?pageSize=${pageSize}&pageNumber=${pageNumber}&reportType=USER&searchText=${searchText}`
  );
}

export {
  getHighRiskEventCount,
  getTopPoliciesBreached,
  getTopRulesBreached,
  getIncidentsPerDay,
  getTopRiskyUsers,
  getTopRiskyDevices,
  getDeviceReportingList,
  getEmailReportingList
};
