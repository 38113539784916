import { properties } from "../properties";
import api from "../services/api";

function updatePolicy(policyId, values) {
  return api.put(`${properties.adminService}/policy/${policyId}`, values);
}

function addPolicy(values) {
  return api.post(`${properties.adminService}/policy`, values);
}

function fetchPolicy(policyId) {
  return api.get(`${properties.adminService}/policy/${policyId}`);
}

function fetchAllPoliciesByDestination(destinationId, searchText = null, pageNumber, pageSize) {
  let url = `${properties.adminService}/policy/fetchAll?destinationId=${destinationId}&`;
  if (pageSize && pageNumber != undefined) {
    url += `pageSize=${pageSize}&pageNumber=${pageNumber}&`;
  }
  if (searchText != null) url += `searchText=${searchText}`;
  return api.get(url);
}

function deletePolicy(policyId) {
  return api.delete(`${properties.adminService}/policy/${policyId}`);
}

function deleteMultiplePolicies(policyIds) {
  return api.delete(`${properties.adminService}/policy`, {
    data: policyIds
  });
}

function updateIsEnabled(id, jsonPatch) {
  return api.patch(`${properties.adminService}/policy/${id}`, [jsonPatch]);
}

function getEnabledPolicyCount() {
  return api.get(`${properties.adminService}/policy/enabled/count`);
}

function addEmailPolicy(values) {
  return api.post(`${properties.adminService}/policy/email-policy`, values);
}

function fetchEmailPolicy(policyId) {
  return api.get(`${properties.adminService}/policy/email-policy/${policyId}`);
}

function updateEmailPolicy(policyId, values) {
  return api.patch(`${properties.adminService}/policy/email-policy/${policyId}`, values);
}

export {
  updatePolicy,
  fetchPolicy,
  fetchAllPoliciesByDestination,
  addPolicy,
  deletePolicy,
  deleteMultiplePolicies,
  updateIsEnabled as updateIsEnabledForPolicy,
  getEnabledPolicyCount,
  fetchEmailPolicy,
  addEmailPolicy,
  updateEmailPolicy
};
