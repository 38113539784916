import * as React from "react";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useState } from "react";
import RuleForm from "../rule/RuleForm";
import AssignFieldsDropdown from "../utility/AssignFieldsDropdown";
import {
  fetchAndFormatDictionaryRules,
  setCurrentDictionaryRulesForClassification
} from "../../utils/Helpers";
import {
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
  InputLabel,
  TextField,
  Stack,
  List
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import { useEffect } from "react";
import {
  createClassification,
  fetchClassification,
  updateClassification
} from "../../API/classification";
import { fetchAllClassificationCustomRules } from "../../API/rule";
import FormShimmering from "../utility/FormShimmer";
import "./classification.css";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function ClassificationForm({
  open,
  setOpen,
  setFeedback,
  selectedClassificationId,
  setFetchCount
}) {
  const [openRule, setOpenRule] = useState(false);
  const [dictionaryRuleIds, setDictionaryRuleIds] = useState([]);
  const [customRuleList, setCustomRuleList] = useState([]);
  const [selectedRule, setSelectedRule] = useState({
    id: "",
    name: "",
    occurrences: 1,
    criteriaList: [
      {
        criteriaType: "",
        criteriaValue: ""
      }
    ]
  });
  const [loading, setLoading] = useState(selectedClassificationId);

  const user = useSelector(({ auth }) => auth.currentUser);

  let formType = selectedClassificationId ? "Update" : "Add";

  useEffect(() => {
    if (selectedClassificationId) {
      fetchClassification(selectedClassificationId)
        .then((response) => {
          formik.setValues(response.data);
        })
        .catch((error) => console.log(error.response.data))
        .finally(() => {
          setLoading(false);
        });

      fetchAllClassificationCustomRules(selectedClassificationId)
        .then((response) => setCustomRuleList(response.data))
        .catch((error) => console.log(error));
    } else {
      formik.setValues(formik.initialValues);
      setCustomRuleList([]);
    }
  }, [selectedClassificationId, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const validate = Yup.object({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required")
  });

  function submitForm(values, resetForm) {
    const formValues = {
      ...values,
      customRuleList: customRuleList,
      dictionaryRuleIds: dictionaryRuleIds
    };
    let promise;
    if (formType === "Add") {
      promise = createClassification(formValues);
    } else {
      promise = updateClassification(formValues);
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Classification ${message} successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  }

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      description: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  const handleCreateNewRule = () => {
    setOpenRule(true);
    setSelectedRule({
      id: "",
      name: "",
      occurrences: 1,
      criteriaList: [
        {
          criteriaType: "",
          criteriaValue: ""
        }
      ]
    });
  };

  const handleDelete = (name) => {
    setCustomRuleList(
      customRuleList.filter((customRule) => customRule.name !== name)
    );
  };

  const handleEdit = (name) => {
    setOpenRule(true);
    setSelectedRule(customRuleList.find((customRule) => customRule.name === name));
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <div>
        <Toolbar />
        <br />
        <div className="close-button">
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="drawer-form-container">
          {loading ? (
            <FormShimmering />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <h5>{formType} Classification</h5>
              <Divider sx={{ opacity: 1 }} />
              <div className="form-input">
                <InputLabel
                  htmlFor="classification-name"
                  className="form-input-label"
                  required
                >
                  Name
                </InputLabel>
                <TextField
                  size="small"
                  id="classification-name"
                  placeholder="Name"
                  className="form-textfield"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div className="form-input">
                <InputLabel
                  htmlFor="classification-description"
                  className="form-input-label"
                  required
                >
                  Description
                </InputLabel>
                <TextField
                  multiline
                  rows={2}
                  size="small"
                  id="classification-description"
                  placeholder="Description"
                  className="form-textfield"
                  fullWidth
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </div>

              <InputLabel className="form-input-label">
                Select Rules From Dictionary
              </InputLabel>
              <AssignFieldsDropdown
                open = {open}
                className="rule-dictonary"
                customerId={user.userId}
                setFieldIds={setDictionaryRuleIds}
                fetchAndFormatFields={fetchAndFormatDictionaryRules}
                setCurrentFields={setCurrentDictionaryRulesForClassification}
                fieldPlaceholder={"Rules From Dictionary"}
                onSearch={false}
                parentId={selectedClassificationId}
                formType={formType}
              />
              <br />
              {customRuleList.length > 0 ? (
                <>
                  <InputLabel className="form-input-label">
                    Custom Rules
                  </InputLabel>
                  <Divider sx={{ opacity: 1 }} />
                  <List dense>
                    {customRuleList.map((customRule) => (
                      <ListItem>
                        <ListItemText primary={customRule.name} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleEdit(customRule.name)}
                          >
                            <EditIcon />
                          </IconButton>
                          &emsp;
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDelete(customRule.name)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  <Divider sx={{ opacity: 1 }} />
                  <br />
                </>
              ) : null}

              <Button
                onClick={handleCreateNewRule}
                startIcon={<AddCircleIcon />}
              >
                Add Custom Rule
              </Button>
              <br />
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  className="primary-button-outlined"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-button-filled"
                  variant="contained"
                  type="submit"
                  startIcon={
                    formType == "Update" ? (
                      <BeenhereOutlinedIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                >
                  {formType}
                </Button>
              </Stack>
            </form>
          )}
        </div>
      </div>
      <div>
        <RuleForm
          openRule={openRule}
          setOpenRule={setOpenRule}
          customRuleList={customRuleList}
          setCustomRuleList={setCustomRuleList}
          setFeedback={setFeedback}
          selectedRule={selectedRule}
        />
      </div>
    </div>
  );
}

export default ClassificationForm;
