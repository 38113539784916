import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  deleteUser,
  getAllUsersWithFilter,
  fetchAllUsersByUserGroupId
} from "../../API/users";
import TableComponent from "../utility/Table";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import UserForm from "./UserForm";
import {
  fetchAndFormatUserGroups,
  setCurrentUserGroupsForPolicy
} from "../../utils/Helpers";
import PageTitle from "../utility/PageTitle";
import BulkAction from "../utility/BulkAction";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Pagination from "../utility/Pagination";

function UsersList({ setFeedback }) {
  const [users, setUsers] = useState(null);
  const [inputText, setInputText] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [fetchCount, setFetchCount] = useState(0);
  const [selectedRecordId, setSelectedRecordId] = useState([]);
  const [usersSelected, setUsersSelected] = useState(new Set());
  const [userGroupIds, setUserGroupIds] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [bulkActions, setBulkActions] = useState(["Assign To Group"]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const userType = "user";

  useEffect(() => {
    getAllUsersWithFilter(false, pageSize, pageNumber, searchText)
      .then(({ data }) => {
        setUsers(
          data.userList.map(({ endUserId, ...rest }) => {
            return { id: endUserId, ...rest };
          })
        );
        setTotalCount(data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching users!",
          state: true
        });      });
  }, [fetchCount, userType, pageNumber, searchText, pageSize]);

  useEffect(() => {
    setInputText("");
  }, [users, userType]);

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = () => {
    deleteUser(selectedId)
      .then(function (response) {
        setUsers(users.filter((user) => user.id !== selectedId));
        setFeedback({
          severity: "success",
          message: "User deleted successfully",
          state: true
        });
        setFetchCount((prev) => prev + 1);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId(null);
  };

  const handleView = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  return (
    <div>
      <div className="main-title-heading-container">
        <PageTitle subheading="Manage Your Organization's Users" />
        <Button className="btn-primary-main-add" onClick={handleClickOpen}>
          <Box className="btns-with-icon">
            <PersonAddAlt1Icon />
            <Typography className="btns-with-icon-text"> Add User </Typography>
          </Box>
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "798px"
        }}
      >
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Username"}
        />
      </div>
      <div>
        <BulkAction
          bulkActions={bulkActions}
          fetchAndFormatFields={fetchAndFormatUserGroups}
          fieldPlaceholder={"User Group"}
          fieldLabel={"User Group"}
          onSearch={false}
          setCurrentFields={setCurrentUserGroupsForPolicy}
          selectedRecordId={selectedRecordId}
          setFeedback={setFeedback}
        />
      </div>
      <TableComponent
        headings={["id", "email", "username", "firstName", "lastName"]}
        data={users}
        options={{ hideIds: true }}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleView,
            hasParameter: true
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true
          }
        ]}
        styles={{
          table: { maxHeight: 425 }
        }}
        multiselect={{
          selectedRecordId,
          setSelectedRecordId
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <UserForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedUserId={selectedId}
        setFetchCount={setFetchCount}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default UsersList;
