import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  ListItemButton,
  FormControlLabel,
  InputLabel,
  Button,
  Box
} from "@mui/material";
import { useState, useEffect } from "react";
import { localSearch } from "./LocalSearch";
import SearchBar from "./SearchBar";
import StyledSwitch from "./StyledSwitch";
import NoRecordsFound from "../assets/Illustrator/NoRecordsFound";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { OpenInNew, TableView } from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';


export default function CheckboxSelectorListWithSearch({
  setFeedback,
  fields,
  fetchAndFormatFields,
  selectedRecords,
  setSelectedRecords,
  heading,
  maxHeight,
  setNewRecords,
  searchPlaceholder,
  inputValidator,
  options,
  value
}) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const searchColumnNames = ["fieldValue"];
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (fields) {
      setData(fields);
      setFilteredData(fields);
    } else {
      fetchAndFormatFields()
        .then((data) => {
          if (data) {
            setData(data);
            setFilteredData(data);
          }
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "There is an issue while fetching fields.",
            state: true
          });
        });
    }
  }, [fields, refresh]);

  useEffect(() => {
    setFilteredData(localSearch(data, searchColumnNames, searchText));
  }, [data, searchText]);

  const handleToggle = (value) => () => {
    const currentIndex = selectedRecords.indexOf(value);
    const newChecked = [...selectedRecords];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedRecords(newChecked);
  };

  const handleAddNewElement = () => {
    if (!filteredData.length) {
      if (inputValidator(searchText.trim())) {
        setNewRecords((prevRecords) => [...prevRecords, searchText.trim()]);
        setSearchText("");
      } else {
        setFeedback({
          severity: "error",
          message: "Please enter valid value.",
          state: true
        });
      }
    }
  };

  const renderListItem = (item, isChecked, labelId) => (
    <ListItem key={item.fieldId} disablePadding>
      <ListItemButton
        role={undefined}
        onClick={handleToggle(item.fieldId)}
        dense
      >
        <ListItemIcon>
          <Checkbox
            size="small"
            checked={isChecked}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
            sx={{ padding: "0" }}
          />
        </ListItemIcon>
        <ListItemText id={labelId} primary={item.fieldValue} />
      </ListItemButton>
    </ListItem>
  );

  const pathname = value === 2 ? "/classification-list" : value === 3 ? "/user-groups" : "";
  const url = `${pathname}`;

  return (
    <div>
      <InputLabel className="form-input-label">{heading}</InputLabel>
      {options?.showSearchBar && (
        <div className="d-flex align-items-start ">
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
            fullWidth={true}
            onEnterAction={handleAddNewElement}
            styles={{ marginBottom: "10px" }}
          />
          <Button
            className="primary-button-filled text-white ms-3 "
            size="small"
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={
              () => setRefresh(!refresh)
            }
          >
            Refresh
          </Button>
        </div>
      )
      }
      {
        options?.showFilters && (
          <FormControlLabel
            control={
              <StyledSwitch
                onChange={(event) => setShowSelectedOnly(event.target.checked)}
              />
            }
            label="Show selected only &emsp;"
            labelPlacement="start"
            sx={{ marginLeft: "unset", marginTop: "5px", marginBottom: "5px" }}
          />
        )
      }
      {
        filteredData.length ? (
          <List className="checkbox-list" sx={{ maxHeight: maxHeight }}>
            {filteredData.map((item) => {
              const labelId = `checkbox-list-label-${item.fieldId}`;
              const isChecked = selectedRecords.includes(item.fieldId);

              if (
                (options?.showUncheckedOnly && !isChecked) ||
                (showSelectedOnly && isChecked) ||
                (!options?.showUncheckedOnly && !showSelectedOnly)
              ) {
                return renderListItem(item, isChecked, labelId);
              }

              return null;
            })}
          </List>
        ) : options?.addRecordOnNotFound ? (
          <Button
            type="button"
            onClick={handleAddNewElement}
            startIcon={<AddCircleIcon />}
            sx={{ wordBreak: "break-all", display: "inline" }}
          >
            Add '{searchText}'
          </Button>
        ) : (
          <div className="no-record-found-container">
            <div>
              <NoRecordsFound height={150} />
            </div>
            <div className="no-record-found-text">No Records Found</div>
            <br />
            <Box className="text-center">
              <Button
                className="primary-button-filled text-white ms-3 "
                size="small"
                variant="outlined"
                endIcon={<OpenInNew />}
                href={url}
                target="_blank"
              >
                {`Add ${value === 2 ? "Classifications" : value === 3 ? "Associate Groups" : ""}`}
              </Button>
            </Box>
          </div>
        )
      }
    </div >
  );
}
