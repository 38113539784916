import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@mui/material";
import { useRef } from "react";
import { useState } from "react";
import { DEVICE_TYPE } from "../../utils/Constants";
import { agentDownload } from "../../API/agentDownload";

export default function AgentDownloadButton({ setFeedback }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const deviceTypeList = [DEVICE_TYPE.BYOD, DEVICE_TYPE.COMPANY_OWNED];

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onSelectHandler = (deviceType) => {
    const packageRequestJson = {
      agentVersion: "1.0",
      needUpgrade: false,
      fullAgentParcel: true,
      deviceType: deviceType
    };

    agentDownload(packageRequestJson)
      .then(() => {
        setFeedback({
          severity: "success",
          message: `Download started`,
          state: true
        });
      })
      .catch(() =>
        setFeedback({
          severity: "error",
          message: `Something went wrong`,
          state: true
        })
      );
  };

  return (
    <div>
      <Button
        className="btn-primary-main-add"
        onClick={handleToggle}
        ref={anchorRef}
      >
        <Box className="btns-with-icon">
          <loadIcon />
          <Typography className="btns-with-icon-text">Download Module</Typography>
        </Box>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  className="box-shadow"
                >
                  {deviceTypeList.length === 0 ? (
                    <MenuItem disabled>{"No actions"}</MenuItem>
                  ) : (
                    deviceTypeList.map((deviceType) => (
                      <MenuItem
                        onClick={(abc) => {
                          setOpen(false);
                          onSelectHandler(deviceType);
                        }}
                      >
                        {deviceType}
                      </MenuItem>
                    ))
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
