import React from "react";
import ReportList from "../reporting_dashboard/ReportList";

export default function EmailReportList() {
  const headings = [
    "reportId",
    "userEmail",
    "incidentDttm",
    "policyName",
    "ruleName",
    "risk",
  ];
  const subheading = "Identify Devices Attempting Ethernet Connections";
  return <ReportList headings={headings} destinationId={2} subheading={subheading} />;
}
