import {
  fetchAllClassificationsByCustomerId,
  fetchAllClassificationsByPolicyId
} from "../API/classification";
import {
  fetchAllSiteBlockingCategoriesByCustomerId,
  fetchAllSiteBlockingCategoriesByPolicyId
} from "../API/httpBlocking";
import {
  fetchAllAppBlockingCategoriesByCustomerId,
  fetchAllAppBlockingCategoriesByPolicyId
} from "../API/appBlockingCategory";
import {
  fetchAllDestinations,
  fetchAllDestinationsByPolicyId
} from "../API/destination";
import {
  fetchDevices,
  fetchAllDevicesByDeviceGroupId,
  fetchAgentVersions
} from "../API/devices";
import {
  fetchAllFileCategories,
  fetchAllFileCategoriesByPolicyId
} from "../API/fileCategory";
import { fetchAllOUsByPolicyId } from "../API/ou";
import {
  fetchAllClassificationDictionaryRules,
  fetchAllDictionaryRulesByCustomerId
} from "../API/rule";
import {
  fetchAllGroupsByType,
  fetchAllUserGroupsByPolicyId,
  fetchAllDeviceGroupsByPolicyId
} from "../API/group";
import {
  fetchAllUsersByUserGroupId,
  getAllUsersWithFilter
} from "../API/users";
import { fetchUsbDetails, fetchUsbsByPolicyId } from "../API/usbBlocking";
import { fetchAllPoliciesByDestination } from "../API/policy";
import { fetchAllUrls } from "../API/httpBlocking";


/*
  This will format the date like -> June 24, 2024 at 5:55:52 PM
*/
function formatDateTimeString(date){
  return new Date(date).toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  })
}

function validateDomain(domain){
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
  return domainRegex.test(domain)
}

function validateIp(ip){
  const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:))|(([0-9a-fA-F]{1,4}:){1,7}:)|(([0-9a-fA-F]{1,4}:){1,6}(:[0-9a-fA-F]{1,4}|:)){1,6}|(([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}|:)){1,5}(:[0-9a-fA-F]{1,4}|:){1,2}|(([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}|:)){1,4}(:[0-9a-fA-F]{1,4}|:){1,3}|(([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}|:)){1,3}(:[0-9a-fA-F]{1,4}|:){1,4}|(([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}|:)){1,2}(:[0-9a-fA-F]{1,4}|:){1,5}|([0-9a-fA-F]{1,4}:)(:[0-9a-fA-F]{1,4}|:){1,6}|:(:[0-9a-fA-F]{1,4}|:){1,7}|::([0-9a-fA-F]{1,4}|:){1,8}$/;
  return (ipv4Regex.test(ip) || ipv6Regex.test(ip));
}

function convertStringArrayToIntegerArray(stringArray) {
  return stringArray.map((str) => parseInt(str, 10));
}

function stringToColor(string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function capitalizefirstLetter(s) {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

function calculateLastActive(date) {
  date = new Date(date);
  let currentDate = new Date();
  let difference = currentDate - date;

  let minutes = Math.floor(difference / 60000);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  let months = Math.floor(days / 30);
  let years = Math.floor(months / 12);

  if (years > 0) {
    return years === 1 ? "a year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "a month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "a day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "an hour ago" : `${hours} hours ago`;
  } else {
    return minutes <= 1 ? "just now" : `${minutes} minutes ago`;
  }
}

async function fetchAndFormatDictionaryRules(inputValue) {
  return fetchAllDictionaryRulesByCustomerId()
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatFileCategories(inputValue) {
  return fetchAllFileCategories()
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatUsers(inputValue) {
  return getAllUsersWithFilter()
    .then((response) => {
      const data = response.data.map(({ endUserId, email }) => {
        return { fieldId: endUserId, fieldValue: email };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatDevices(inputValue) {
  return fetchDevices()
    .then((response) => {
      const data = response.data.map(({ id, deviceName }) => {
        return { fieldId: id, fieldValue: deviceName };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatAgentVersion() {
  return fetchAgentVersions()
  .then((response)=>{
    const data = response.data.map((version) => {
      return { filterValue: version, filterId: version };
    });
    return data;
  })
}

async function fetchAndFormatDestinations(inputValue) {
  return fetchAllDestinations()
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatClassifications(inputValue) {
  return fetchAllClassificationsByCustomerId()
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatSiteBlockingCategory(inputValue) {
  return fetchAllSiteBlockingCategoriesByCustomerId()
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatAppBlockingCategory(inputValue) {
  return fetchAllAppBlockingCategoriesByCustomerId()
    .then((response) => {
      const data = response.data.categories.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatUserGroups(inputValue) {
  return fetchAllGroupsByType("USER")
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}
async function fetchAndFormatDeviceGroups(inputValue) {
  return fetchAllGroupsByType("DEVICE")
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatUsbList(inputValue) {
  return fetchUsbDetails()
    .then((response) => {
      const data = response.data.usbs.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatUrls() {
  return fetchAllUrls()
    .then((response) => {
      const data = response.data.map(({ id, url }) => {
        return { fieldId: id, fieldValue: url };
      });
      return data;
    })
}

async function setCurrentWhitelistedUsbs(policyId) {
  return fetchUsbsByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentFileCategoriesForPolicy(policyId) {
  return fetchAllFileCategoriesByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentDestinationsForPolicy(policyId) {
  return fetchAllDestinationsByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentClassificationsForPolicy(policyId) {
  return fetchAllClassificationsByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentSiteBlockingCategoryForPolicy(policyId) {
  return fetchAllSiteBlockingCategoriesByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentAppBlockingCategoryForPolicy(policyId) {
  return fetchAllAppBlockingCategoriesByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentUserGroupsForPolicy(policyId) {
  return fetchAllUserGroupsByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}
async function setCurrentDeviceGroupsForPolicy(policyId) {
  return fetchAllDeviceGroupsByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentUsersForUserGroups(groupId) {
  return fetchAllUsersByUserGroupId(groupId)
    .then((response) => {
      const data = response.data.map(({ endUserId, email }) => {
        return { fieldId: endUserId, fieldValue: email };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentDevicesForDevicesGroups(deviceId) {
  return fetchAllDevicesByDeviceGroupId(deviceId)
    .then((response) => {
      const data = response.data.map(({ id, deviceName }) => {
        return { fieldId: id, fieldValue: deviceName };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentDictionaryRulesForClassification(classification) {
  return fetchAllClassificationDictionaryRules(classification)
    .then((response) => {
      const data = response.data.map(({ id, name }) => {
        return { fieldId: id, fieldValue: name };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function setCurrentOUsForEmailPolicy(policyId) {
  return fetchAllOUsByPolicyId(policyId)
    .then((response) => {
      const data = response.data.map(({ id, ouName }) => {
        return { fieldId: id, fieldValue: ouName };
      });
      return data;
    })
    .catch((error) => {
      console.log(error.data);
    });
}

async function fetchAndFormatPoliciesByDestination(destinationId) {
  if (destinationId) {
    return fetchAllPoliciesByDestination(destinationId)
      .then((response) => {
        const data = response.data.policies.map(({ id, name }) => {
          return { fieldId: id, fieldValue: name };
        });
        return data;
      })
      .catch((error) => {
        console.log(error.data);
      });
  }
}

export {
  capitalizefirstLetter,
  fetchAndFormatDictionaryRules,
  fetchAndFormatFileCategories,
  fetchAndFormatDestinations,
  fetchAndFormatAgentVersion,
  fetchAndFormatClassifications,
  fetchAndFormatSiteBlockingCategory,
  fetchAndFormatAppBlockingCategory,
  fetchAndFormatUserGroups,
  fetchAndFormatUsers,
  fetchAndFormatUsbList,
  setCurrentWhitelistedUsbs,
  setCurrentOUsForEmailPolicy,
  setCurrentFileCategoriesForPolicy,
  setCurrentUsersForUserGroups,
  setCurrentDestinationsForPolicy,
  setCurrentUserGroupsForPolicy,
  setCurrentClassificationsForPolicy,
  setCurrentSiteBlockingCategoryForPolicy,
  setCurrentAppBlockingCategoryForPolicy,
  setCurrentDictionaryRulesForClassification,
  fetchAndFormatDevices,
  setCurrentDevicesForDevicesGroups,
  fetchAndFormatDeviceGroups,
  setCurrentDeviceGroupsForPolicy,
  fetchAndFormatPoliciesByDestination,
  calculateLastActive,
  convertStringArrayToIntegerArray,
  fetchAndFormatUrls,
  validateDomain,
  validateIp,
  stringToColor,
  formatDateTimeString
};
