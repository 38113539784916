import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomTabPanel from "../other/CustomTabPanel";
import DefaultDictionaryRules from "./DefaultDictionaryRules";
import CustomDictionaryRules from "./CustomDictionaryRules";
import PageTitle from "../utility/PageTitle";
import Button from "@mui/material/Button";
import RuleIcon from "@mui/icons-material/Rule";
function props(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function DictionaryRules({ setFeedback }) {
  const [value, setValue] = React.useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [openRule, setOpenRule] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpenRule(true);
    setSelectedId(null);
  };

  return (
    <>
      <div className="main-title-heading-container">
        <PageTitle 
          subheading="Define Rules to Identify Sensitive Data"
        />
        {value == 0 && (
          <div className="add">
            <Button className="btn-primary-main-add" onClick={handleClickOpen}>
              <Box className="btns-with-icon">
                <RuleIcon />
                <Typography className="btns-with-icon-text">Add Rule</Typography>
              </Box>
            </Button>
          </div>
        )}
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Custom" {...props(0)} />
            <Tab label="Default" {...props(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CustomDictionaryRules
            setFeedback={setFeedback}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            openRule={openRule}
            setOpenRule={setOpenRule}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DefaultDictionaryRules
            setFeedback={setFeedback}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
}
