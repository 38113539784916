import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Toolbar,
  IconButton,
  Divider,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Autocomplete
} from "@mui/material";
import { adminApprovalRequest } from "../../API/approval";
import { fetchAllDestinations } from "../../API/destination";
import FormFooter from "../utility/FormFooter";
import CloseIcon from "@mui/icons-material/Close";
import "./approvalRequest.css";
import CustomDateTimePicker from "../utility/DateTimePicker";
import { fetchAndFormatDevices } from "../../utils/Helpers";

export default function TimeBoundApprovalFrom({
  deviceId,
  isApprovalDialogOpen,
  setIsApprovalDialogOpen,
  setFetchCount,
  setFeedback
}) {
  const [destinationList, setDestinationList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);

  const handleApprove = (values, resetForm) => {
    values.startTime = new Date(values.startTime).toISOString();
    values.endTime = new Date(values.endTime).toISOString();

    adminApprovalRequest(values)
      .then(() => {
        setFeedback({
          severity: "success",
          message: `Approved successfully`,
          state: true
        });
        resetForm();
        setFetchCount((fetchCount) => fetchCount + 1);
        setIsApprovalDialogOpen(false);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  };

  const handleClose = () => {
    formik.resetForm();
    setIsApprovalDialogOpen(false);
  };

  useEffect(() => {
    fetchAllDestinations()
      .then(({ data }) => {
        setDestinationList(data.filter((item) => item.id !== 2));
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
    if (!deviceId) {
      fetchAndFormatDevices()
        .then((data) => {
          setDeviceList(data);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "There is an issue fetching device list.",
            state: true
          });
        });
    }
  }, []);

  const validate = Yup.object({
    deviceId: Yup.string()
      .typeError("Please select a device")
      .required("Please select a device"),
    reason: Yup.string()
      .trim()
      .max(300, "Must be 300 characters or less")
      .required("Please enter remarks"),
    recipient: Yup.string().email("Invalid email address"),
    destinationId: Yup.number().required("Required"),
    startTime: Yup.date()
      .required("Start time is required")
      .typeError("Invalid start time")
      .min(new Date(), "Start time must be greater than the current time"),
    endTime: Yup.date()
      .required("End time is required")
      .typeError("Invalid end time")
      .min(Yup.ref("startTime"), "End time must be after start time")
  });

  const formik = useFormik({
    initialValues: {
      deviceId: deviceId ? deviceId : null,
      destinationId: "",
      reason: "",
      recipient: "",
      startTime: "",
      endTime: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      handleApprove(values, resetForm);
    }
  });

  return (
    <div
      className={`drawer-form-wrapper ${
        isApprovalDialogOpen ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        <form onSubmit={formik.handleSubmit}>
          <h5>Time Bound Approval</h5>
          <Divider />

          {!deviceId ? (
            <div className="form-input">
              <InputLabel
                htmlFor="deviceId"
                className="form-input-label"
                required
              >
                Select Device
              </InputLabel>
              <Autocomplete
                size="small"
                id="deviceId"
                options={deviceList}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.fieldId}>
                      {option.fieldValue}
                    </li>
                  );
                }}
                getOptionLabel={(option) => {
                  const device = deviceList.find(
                    (device) => device.fieldId === option
                  );
                  return device ? device.fieldValue : "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Device"
                    error={
                      formik.touched.deviceId && Boolean(formik.errors.deviceId)
                    }
                  />
                )}
                value={formik.values.deviceId}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "deviceId",
                    newValue ? newValue.fieldId : null
                  );
                }}
                noOptionsText={"No options"}
                isOptionEqualToValue={(option, value) => {
                  if (option.fieldId == value) return true;
                  return false;
                }}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  if (!inputValue) return options;
                  return options.filter((option) => {
                    const name = option.fieldValue;
                    return name
                      .toLowerCase()
                      .includes(inputValue.toLowerCase());
                  });
                }}
              />
            </div>
          ) : null}

          <div className="form-input">
            <InputLabel
              htmlFor="destinationId"
              className="form-input-label"
              required
            >
              Select Destination
            </InputLabel>
            <Select
              displayEmpty
              fullWidth
              size="small"
              className="form-selectfield"
              labelId="destination"
              name="destinationId"
              id="destinationId"
              value={formik.values.destinationId}
              onChange={formik.handleChange}
              required
              error={
                formik.touched.destinationId &&
                Boolean(formik.errors.destinationId)
              }
            >
              <MenuItem value="" key="" disabled hidden>
                <span className="placeholder-style">Select Destination</span>
              </MenuItem>
              {destinationList.map((destination, index) => (
                <MenuItem value={destination.id} key={destination.id}>
                  {destination.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="date-inputs">
            <div className="form-input">
              <InputLabel
                htmlFor="startTime"
                className="form-input-label"
                required
              >
                Start date
              </InputLabel>
              <CustomDateTimePicker
                id="startTime"
                time={formik.values.startTime}
                onChangeHandler={(val) =>
                  formik.setFieldValue("startTime", val)
                }
                error={
                  formik.touched.startTime && Boolean(formik.errors.startTime)
                }
              />
            </div>
            <div className="form-input">
              <InputLabel
                htmlFor="endTime"
                className="form-input-label"
                required
              >
                End date
              </InputLabel>
              <CustomDateTimePicker
                id="endTime"
                time={formik.values.endTime}
                onChangeHandler={(val) => formik.setFieldValue("endTime", val)}
                error={formik.touched.endTime && Boolean(formik.errors.endTime)}
              />
            </div>
          </div>
          <div className="form-input">
            <InputLabel htmlFor="recipient" className="form-input-label">
              Send Notification to
            </InputLabel>
            <TextField
              id="recipient"
              name="recipient"
              className="form-textfield"
              fullWidth
              size="small"
              value={formik.values.recipient}
              onChange={formik.handleChange}
              type="email"
              placeholder="example@gmail.com"
              error={
                formik.touched.recipient && Boolean(formik.errors.recipient)
              }
            />
          </div>
          <div className="form-input">
            <InputLabel htmlFor="reason" className="form-input-label" required>
              Remarks
            </InputLabel>
            <TextField
              id="reason"
              className="form-textfield"
              fullWidth
              size="small"
              value={formik.values.reason}
              onChange={formik.handleChange}
              multiline
              rows={2}
              placeholder="Enter the reason for approval"
              error={formik.touched.reason && Boolean(formik.errors.reason)}
            />
          </div>
          <br />
          <FormFooter
            formType={"Save"}
            errors={formik.errors}
            touched={formik.touched}
            handleClose={handleClose}
          />
        </form>
      </div>
    </div>
  );
}
