import { useEffect, useState } from "react";
import TableComponent from "../utility/Table";
import { fetchAllDefaultDictionaryRules } from "../../API/rule";
import SearchBar from "../utility/SearchBar";
import Pagination from "../utility/Pagination";

function DefaultDictionaryRules({ setFeedback }) {

  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchAllDefaultDictionaryRules(pageSize, pageNumber, searchText)
      .then((response) => {
        setData(response.data.rulesList);
        setTotalCount(response.data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching rules.",
          state: true
        });
      });
  }, [pageNumber, searchText, pageSize]);

  const headings = ["id", "name"];

  return (
    <div>
      <div style={{ display: "flex" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Rule Name"}
        />
      </div>
      <TableComponent
        headings={headings}
        data={data}
        options={{ hideIds: true }}
        styles={{
          table: { maxHeight: 395 }
        }}
        customColumnNames={{
          name: "Rule Name"
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
    </div>
  );
}

export default DefaultDictionaryRules;
