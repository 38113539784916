import { properties } from "../properties";
import api from "../services/api";

function agentDownload(packageRequestJson) {
  return api.post(
    `${properties.packagingService}/package/agents`,
    packageRequestJson,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

export {
  agentDownload
};
