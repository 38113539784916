import React, {useState} from "react";
import { DIALOG_TYPES } from "../../../utils/Constants";
import CustomDialog from "../../utility/Dialog";
import { applyRemoteLockToDevice } from "../../../API/devices";

export default function RemoteLockWithConfiramtion({
  confirmRemoteLock,
  setConfirmRemoteLock,
  deviceId,
  setFeedback
}) {
  const [isGeneratedPasswordDialogOpen, setIsGeneratedPasswordDialogOpen] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");

  const handleRemoteLockApplied = () => {
    const configuration = {
      deviceId:deviceId,
      isRemoteLockApplied: true
    };
    applyRemoteLockToDevice(deviceId, configuration)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: `Device locked successfully`,
          state: true
        });
        setConfirmRemoteLock(false);
        setNewPassword(response.data.changedPassword);
        setIsGeneratedPasswordDialogOpen(true);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong while locking the device",
          state: true
        });
      });
  };

  return (
    <>
      <CustomDialog
        open={confirmRemoteLock}
        setOpen={setConfirmRemoteLock}
        title="Confirm Remote Lock"
        content="Are you sure you want to remotely lock this device? If confirmed, the device will be locked and it's password will be reset."
        handleConfirm={handleRemoteLockApplied}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
      <CustomDialog
        open={isGeneratedPasswordDialogOpen}
        setOpen={setIsGeneratedPasswordDialogOpen}
        title={"Please copy the new password"}
        content={newPassword}
      />
    </>
  );
}
