import { useState } from "react";
import BlockUsb from "../UsbBlocking/BlockUsbList";
import PolicyList from "./PolicyList";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CustomTabPanel from "../other/CustomTabPanel";
import PageTitle from "../utility/PageTitle";

function props(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
function UsbPolicy({ setFeedback }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <PageTitle 
          subheading="Block Unauthorized USB Devices and Whitelist Only Approved Ones with Product and Vendor IDs"
        />
        <br />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
            <Tab label="Whitelisted USB" {...props(0)} />
            <Tab label="Policy" {...props(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <BlockUsb setFeedback={setFeedback} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PolicyList setFeedback={setFeedback} />
        </CustomTabPanel>
      </Box>
    </div>
  );
}

export default UsbPolicy;
