import { useEffect, useState } from "react";
import TableComponent from "../utility/Table";
import { getDeviceReportingList, getEmailReportingList } from "../../API/reporting";
import Pagination from "../utility/Pagination";
import "../reporting/device-reports.css";
import PageTitle from "../utility/PageTitle";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { formatDateTimeString } from "../../utils/Helpers";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box } from "@mui/material";
import StyledSwitch from "../utility/StyledSwitch";
import { useDispatch, useSelector } from "react-redux";
import { setAutoRefresh } from "../../services";
import Feedback from "../utility/Feedback";

export default function ReportList({ headings, destinationId, subheading }) {
  const [data, setData] = useState(null);
  const [totalCount, setTotalCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [openRows, setOpenRows] = useState([]);
  const [refreshTime, setRefreshTime] = useState(5000)
  const pageSize = 10;
  const [feedback, setFeedback] = useState({
    severity: "error",
    message: "",
    state: false
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();
  const autoRefresh = useSelector((state) => state.auth.isAutoRefresh)

  const getReportingList = () => {
    getDeviceReportingList(destinationId, rowsPerPage, page, searchText)
      .then(({ data }) => {
        const processedData = data.reportList.map((item) => {
          const ethernetConnectionName = item.details
            ? item.details["Ethernet Connection Name"]
            : "--";
          const blockedapplications = item.details
            ? item.details["Blocked Application"]
            : "--";
          const blockeddomains = item.details
            ? item.details["Blocked Domains"]
            : "--";
          return {
            ...item,
            incidentDttm: formatDateTimeString(item.incidentDttm),
            id: item.reportId,
            policyName: item.policyName == null ? "--" : item.policyName,
            "Ethernet Connection Name": ethernetConnectionName,
            "Blocked Application": blockedapplications
          };
        });
        setTotalCount(data.totalItems);
        setData(processedData);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error,
          state: true,
        })
      });
  }

  const displayEmailReportingList = () => {
    getEmailReportingList(rowsPerPage, page)
      .then(({ data }) => {
        const processedData = data.reportList.map((item) => {
          return {
            ...item,
            id: item.reportId,
            incidentDttm: new Date(item.incidentDttm).toLocaleString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            })
          };
        });
        setTotalCount(data.totalItems);
        setData(processedData);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error,
          state: true,
        })
      });
  }

  useEffect(() => {
    if (destinationId === 2) {
      displayEmailReportingList();
    }
    else {
      getReportingList();
    }
  }, [pageNumber, destinationId, searchText, rowsPerPage, page]);

  useEffect(() => {
    if (autoRefresh) {
      if (destinationId === 2) {
        const refreshInterval = setInterval(() => {
          displayEmailReportingList();
        }, refreshTime)
        return () => clearInterval(refreshInterval)
      }
      else {
        const refreshInterval = setInterval(() => {
          getReportingList();
        }, refreshTime)
        return () => clearInterval(refreshInterval)
      }
    }
  }, [refreshTime, autoRefresh, destinationId, dispatch])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowWidth > 1536) {
      setRowsPerPage(100);
    }
    else {
      setRowsPerPage(10)
    }
  }, [windowWidth]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const chipsStyles = {
    risk: {
      HIGH: {
        icon: (
          <ArrowUpwardIcon style={{ color: "#B91C1C", fontSize: "medium" }} />
        ),
        style: {
          color: "#B91C1C",
          backgroundColor: "#FEE2DC",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      MEDIUM: {
        icon: (
          <HorizontalRuleIcon
            style={{ color: "#B91C1C", fontSize: "medium" }}
          />
        ),
        style: {
          color: "#7B341E",
          backgroundColor: "#FEEBC8",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      LOW: {
        icon: (
          <ArrowDownwardIcon style={{ color: "#15803D", fontSize: "medium" }} />
        ),
        style: {
          color: "#15803D",
          backgroundColor: "#DCFCE7",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      }
    }
  };

  const collapsibleDetails =
    destinationId === 1 || destinationId === 3 || destinationId === 2
      ? { openRows, setOpenRows }
      : undefined;

  const handleRefreshChange = (event) => {
    dispatch(setAutoRefresh(event.target.checked));
  }

  return (
    <div>
      <Feedback feedback={feedback} setFeedback={setFeedback} />
      <Box className="d-flex justify-content-between align-items-center">
        <PageTitle subheading={subheading} />
        <Box className="d-flex">
          <FormGroup>
            <FormControlLabel control={<StyledSwitch />} label="Auto Refresh" checked={autoRefresh} onClick={handleRefreshChange} />
          </FormGroup>
        </Box>
      </Box>
      <TableComponent
        headings={headings}
        data={data}
        styles={{
          risk: "chip",
          table: { maxHeight: windowWidth > 1536 ? "auto" : 480 }
        }
        }
        customColumnStyles={{
          deviceName: { fontWeight: 500 }
        }}
        chipsStyles={chipsStyles}
        customColumnNames={{
          id: "ID",
          incidentDttm: "Incident Time",
          risk: "Risk Level",
          "Ethernet Connection Name": "Ethernet Connection Name",
          "Blocked Domain": "Blocked Domain",
          "Blocked Application": "Blocked Application"
        }}
        options={{ hideIds: true }}
        collapsibleDetails={collapsibleDetails}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      ) : null}
    </div>
  );
}
