import {
  Toolbar,
  IconButton,
  Divider,
  InputLabel,
  TextField,
  Grid,
  InputAdornment
} from "@mui/material";
import { useEffect, useState } from "react";
import GroupFormShimmering from "../utility/GroupFormShimmer";
import "./block-url.css";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {
  addSiteBlockingUrls,
  fetchDomainCategory,
  deleteDomainCategory,
  updateDomainCategory
} from "../../API/httpBlocking";
import CloseIcon from "@mui/icons-material/Close";
import FormFooter from "../utility/FormFooter";
import { fetchAndFormatUrls } from "../../utils/Helpers";
import CheckboxSelectorListWithSearch from "../utility/CheckBoxSelectorListWithSearch";
import SelectedList from "../utility/SelectedList";
import LanguageIcon from "@mui/icons-material/Language";
import { validateDomain, validateIp } from "../../utils/Helpers";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import { Close } from "@mui/icons-material";

const filter = createFilterOptions();

function BlockCategoryForm({
  openForm,
  setOpenForm,
  setFeedback,
  categoriesList,
  setCategoryFetchCount,
  selectedCategory,
  setSelectedCategory
}) {
  const [loading, setLoading] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [newUrlList, setNewUrlList] = useState([]);
  const [fetchUrl, setFetchUrl] = useState(0);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  let formType = selectedCategory?.id ? "Update" : "Add";

  useEffect(() => {
    setLoading(true);
    fetchAndFormatUrls()
      .then((data) => {
        if (data) {
          setDomainList(data);
        }
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching domains.",
          state: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchUrl]);

  useEffect(() => {
    if (selectedCategory?.id) {
      fetchDomainCategory(selectedCategory.id)
        .then((response) => {
          setSelectedUrls(response.data.domainIds);
          setNewUrlList([]);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "There is an issue while fetching domain category.",
            state: true
          });
        });
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (formType == "Add") {
      setSelectedCategory(null);
      setSelectedUrls([]);
      setNewUrlList([]);
    }
  }, [openForm]);

  const handleSubmit = () => {
    if (!selectedCategory || (!newUrlList.length && !selectedUrls.length)) {
      setFeedback({
        severity: "error",
        message: !selectedCategory ? "Please Select a Category" : "Please select a Domain",
        state: true
      });
      return;
    }
  
    const data = {
      categoryName: selectedCategory.name,
      domainIds: selectedUrls,
      domainList: newUrlList.map(url => ({
        url: url,
        isEnabled: true
      }))
    };
  
    const handleSuccess = (message) => {
      setCategoryFetchCount(prevCount => prevCount + 1);
      setOpenForm(false);
      setFeedback({
        severity: "success",
        message: message,
        state: true
      });
      if (newUrlList.length) {
        setFetchUrl(prevFetchUrl => prevFetchUrl + 1);
      }
    };
  
    const handleError = (error) => {
      setFeedback({
        severity: "error",
        message: error.response?.data || "An error occurred",
        state: true
      });
    };
  
    if (selectedCategory?.id) {
      updateDomainCategory(selectedCategory.id, data)
        .then(() => handleSuccess("Category updated successfully"))
        .catch(handleError);
    } else {
      addSiteBlockingUrls(data)
        .then(() => handleSuccess("Domains added successfully"))
        .catch(handleError);
    }
  };

  const handleClose = () => {
    setOpenForm(false);
  };

  const validateDomainOrIp = (input) => {
    return (validateDomain(input) || validateIp(input));
  };

  const handleDeleteCategory = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteDomainCategory(selectedCategory.id)
      .then((response) => {
        setCategoryFetchCount((categoryFetchCount) => categoryFetchCount - 1);
        setFeedback({
          severity: "success",
          message: "Category deleted successfully",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while deleting the category.",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setOpenForm(false);
      });
  };

  return (
    <div
      className={`drawer-form-wrapper form-width ${
        openForm ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <GroupFormShimmering />
        ) : (
          <>
            <h6>Domains and Categories</h6>
            <Divider sx={{ opacity: 1 }} />
            <Grid
              container
              spacing={2}
              className="group-form-container"
              columns={24}
            >
              <Grid item md={12}>
                <div className="form-input">
                  <InputLabel
                    htmlFor="categoryName"
                    className="form-input-label"
                    required
                  >
                    Category Name
                  </InputLabel>

                  {selectedCategory?.id ? (
                    <TextField
                      id="category_name"
                      className="form-textfield"
                      fullWidth
                      size="small"
                      value={selectedCategory.name}
                      onChange={(event) => {
                        setSelectedCategory({
                          ...selectedCategory,
                          name: event.target.value
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setSelectedCategory(null);
                              }}
                            >
                              <Close />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  ) : (
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="select-category"
                      value={selectedCategory}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setSelectedCategory({
                            name: newValue
                          });
                        } else if (newValue && newValue.inputValue) {
                          setSelectedCategory({
                            name: newValue.inputValue
                          });
                        } else {
                          setSelectedCategory(newValue);
                        }
                      }}
                      options={categoriesList}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Enter New Category Name / Select Existing"
                          {...params}
                          name="categoryName"
                          required
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.name
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `${inputValue} (New Category)`
                          });
                        }
                        return filtered;
                      }}
                    />
                  )}
                </div>
                <div className="form-input">
                  <CheckboxSelectorListWithSearch
                    fields={domainList}
                    heading={"Domains"}
                    setFeedback={setFeedback}
                    fetchAndFormatFields={fetchAndFormatUrls}
                    selectedRecords={selectedUrls}
                    setSelectedRecords={setSelectedUrls}
                    options={{
                      showFilters: false,
                      showSearchBar: true,
                      addRecordOnNotFound: true,
                      showUncheckedOnly: true
                    }}
                    maxHeight={"300px"}
                    setNewRecords={setNewUrlList}
                    inputValidator={validateDomainOrIp}
                    searchPlaceholder={"Enter Domain"}
                  />
                </div>
              </Grid>
              <Grid md={1} item sx={{ Height: "100%" }}>
                <Divider orientation="vertical" sx={{ opacity: 1 }} />
              </Grid>
              <Grid item md={11}>
                <div style={{ marginTop: "12px" }}>
                  <h6>Selected Domains</h6>
                  <SelectedList
                    data={domainList}
                    selectedRecordId={selectedUrls}
                    setSelectedRecordId={setSelectedUrls}
                    primaryText={"fieldValue"}
                    field={"fieldId"}
                    icon={<LanguageIcon color="action" />}
                    iconStyles={{
                      width: 24,
                      height: 24,
                      bgcolor: "rgba(0, 0, 0, 0)"
                    }}
                    newRecordList={newUrlList}
                    setNewRecordList={setNewUrlList}
                    showFavicon={true}
                  />
                </div>
              </Grid>
            </Grid>
            <FormFooter
              formType={"Save"}
              handleSubmit={handleSubmit}
              showDeleteButton={selectedCategory?.id ? true : false}
              handleDelete={handleDeleteCategory}
              deleteButtonText={`Delete ${selectedCategory?.name} Category`}
              handleClose={handleClose}
            />
            <CustomDialog
              open={isDeleteConfirmationOpen}
              title={"Are you sure you want to delete?"}
              content={
                "This will only delete the category and won't delete any linked URLs."
              }
              setOpen={setIsDeleteConfirmationOpen}
              handleConfirm={handleConfirmDelete}
              dialogType={DIALOG_TYPES.CONFIRM}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default BlockCategoryForm;
