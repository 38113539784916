import { Grid, Button } from "@mui/material";
import StyledSwitch from "../../utility/StyledSwitch";
import { updateDeviceUsingPatch } from "../../../API/devices";
import RemoteLockWithConfiramtion from "../device_actions/RemoteLockWithConfirmation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function DefaultProfileActionsTab({
  deviceData,
  setDeviceData,
  setFeedback
}) {
  const navigate = useNavigate();
  const deviceId = deviceData.id;
  const [confirmRemoteLock, setConfirmRemoteLock] = useState(false);

  const handleUpdateIsPasswordChangePolicyApplied = (event) => {
    const isChecked = event.target.checked;
    const message = isChecked ? "applied" : "is no longer applied";
    const jsonPatch = [
      {
        op: "replace",
        path: "/isPasswordChangePolicyApplied",
        value: isChecked
      }
    ];

    updateDeviceUsingPatch(deviceId, jsonPatch)
      .then(() => {
        setFeedback({
          severity: "success",
          message: `Password policy ${message} for the device`,
          state: true
        });
        setDeviceData((prevDeviceData) => {
          return {
            ...prevDeviceData,
            isPasswordChangePolicyApplied: isChecked
          };
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      });
  };

  const handleUpdateIsPasswordManagerEnabled = (event) => {
    const isChecked = event.target.checked;
    const message = !isChecked ? "enabled" : "disabled";
    const jsonPatch = [
      {
        op: "replace",
        path: "/isPasswordManagerEnabled",
        value: isChecked
      }
    ];

    updateDeviceUsingPatch(deviceId, jsonPatch)
      .then(() => {
        setFeedback({
          severity: "success",
          message: `Password manager ${message} for the device`,
          state: true
        });
        setDeviceData((prevDeviceData) => {
          return {
            ...prevDeviceData,
            isPasswordManagerEnabled: isChecked
          };
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while updating.",
          state: true
        });
      });
  };

  return (
    <>
      <Grid container columns={24} className="device-actions" spacing={3}>
        <Grid item md={5} className="device-actions-heading">
          Apply Password Policy :
        </Grid>
        <Grid item md={18}>
          <StyledSwitch
            checked={deviceData.isPasswordChangePolicyApplied}
            onChange={(event) =>
              handleUpdateIsPasswordChangePolicyApplied(event)
            }
          />
          &emsp;|&emsp;
          <Button
            onClick={() =>
              navigate("/settings", {
                state: { tabValue: 1 }
              })
            }
          >
            [ Configure Password Policy ]
          </Button>
        </Grid>
        <Grid item md={5} className="device-actions-heading">
          Remote Lock :
        </Grid>
        <Grid item md={18}>
          <Button variant="outlined" onClick={() => setConfirmRemoteLock(true)}>
            Apply Remote Lock
          </Button>
        </Grid>
        <Grid item md={5} className="device-actions-heading">
          Disable Password Manager (Google Chrome) :
        </Grid>
        <Grid item md={18}>
          <StyledSwitch
            checked={deviceData.isPasswordManagerEnabled}
            onChange={(event) =>
              handleUpdateIsPasswordManagerEnabled(event)
            }
          />
        </Grid>
      </Grid>
      <RemoteLockWithConfiramtion
        confirmRemoteLock={confirmRemoteLock}
        setConfirmRemoteLock={setConfirmRemoteLock}
        deviceId={deviceId}
        setFeedback={setFeedback}
      />
    </>
  );
}
