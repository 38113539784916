import { useState, useEffect } from "react";
import { Button, Alert, Box, Typography, Tabs, Tab } from "@mui/material";
import "./organizational-domains.css";
import CustomAccordian from "./CustomAccordian";
import OrganizationalDomainForm from "./OrganizationalDomainForm";

import {
  fetchAllOrganizationDomain,
  deleteOrganizationDomain
} from "../../API/organizationalDomains";
import CustomDialog from "../utility/Dialog";
import { DIALOG_TYPES } from "../../utils/Constants";
import PageTitle from "../utility/PageTitle";
import LanguageIcon from "@mui/icons-material/Language";
import CustomTabPanel from "../other/CustomTabPanel";

export default function OrganizationalDomains({ setFeedback }) {
  const [isDefault, setIsDefault] = useState(false);
  const [updatedDefaultDomains, setUpdatedDefaultDomains] = useState([]);
  const [defaultDomains, setDefaultDomains] = useState([]);
  const [customDomains, setCustomDomains] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedOrganizationDomain, setSelectedOrganizationDomain] =
    useState("");
  const [fetchCount, setFetchCount] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function props(index) {
    return {
      id: `organizational-domain-tab-${index}`,
      "aria-controls": `organizational-tabpanel-${index}`
    };
  }
  useEffect(() => {
    fetchAllOrganizationDomain()
      .then((response) => {
        if (response.data) {
          processLists(
            response.data.customDomains,
            response.data.defaultDomains
          );
        }
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      });
  }, [fetchCount]);

  function processLists(customDomains, defaultDomains) {
    const customDomainsSet = new Set(customDomains.map((obj) => obj.name));
    const defaultDomainsSet = new Set(defaultDomains.map((obj) => obj.name));

    setUpdatedDefaultDomains(
      customDomains.filter((obj) => defaultDomainsSet.has(obj.name))
    );
    setCustomDomains(
      customDomains.filter((obj) => !defaultDomainsSet.has(obj.name))
    );
    setDefaultDomains(
      defaultDomains.filter((obj) => !customDomainsSet.has(obj.name))
    );
  }

  const addCustomOrganizationDomain = () => {
    setSelectedOrganizationDomain("");
    setIsDefault(false);
    setOpenForm(true);
  };

  const handleConfirmDelete = () => {
    deleteOrganizationDomain(selectedOrganizationDomain.id)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: "Deleted Successfully",
          state: true
        });
        setCustomDomains(
          customDomains.filter(
            (obj) => obj.id !== selectedOrganizationDomain.id
          )
        );
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: error.response.data,
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedOrganizationDomain("");
      });
  };

  return (
    <>
      <div>
        <div className="main-title-heading-container">
          <PageTitle subheading="Specify a list of approved domains for organizational platforms like Google, Office 365, and others." />
          <Button
            className="btn-primary-main-add"
            variant="contained"
            onClick={addCustomOrganizationDomain}
            sx={{ float: "right" }}
          >
            <Box className="btns-with-icon">
              <LanguageIcon sx={{ height: "24px", width: "20px" }} />
              <Typography className="btns-with-icon-text">
                Add Organizational Domain
              </Typography>
            </Box>
          </Button>
        </div>
        <div className="org-domain">
        <div sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs"
            >
              <Tab label="Default" {...props(0)} />
              <Tab label="Custom" {...props(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            {updatedDefaultDomains.map((domain,index) => (
              <CustomAccordian
                key={index}
                domain={domain}
                isDefault={true}
                setOpenForm={setOpenForm}
                setSelectedOrganizationDomain={setSelectedOrganizationDomain}
                setIsDefault={setIsDefault}
                setIsDeleteConfirmationOpen={setIsDeleteConfirmationOpen}
              />
            ))}
            {defaultDomains.map((domain,index) => (
              <CustomAccordian
                domain={domain}
                key={index}
                isDefault={true}
                setOpenForm={setOpenForm}
                setSelectedOrganizationDomain={setSelectedOrganizationDomain}
                setIsDefault={setIsDefault}
                setIsDeleteConfirmationOpen={setIsDeleteConfirmationOpen}
              />
            ))}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {customDomains.map((domain,index) => (
              <CustomAccordian
                key={index}
                domain={domain}
                isDefault={false}
                setOpenForm={setOpenForm}
                setSelectedOrganizationDomain={setSelectedOrganizationDomain}
                setIsDefault={setIsDefault}
                setIsDeleteConfirmationOpen={setIsDeleteConfirmationOpen}
              />
            ))}
          </CustomTabPanel>
        </div>
        </div>
      <OrganizationalDomainForm
        open={openForm}
        setOpen={setOpenForm}
        setFeedback={setFeedback}
        selectedOrganizationDomain={selectedOrganizationDomain}
        setFetchCount={setFetchCount}
        isDefault={isDefault}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
    </>
  );
}
   
