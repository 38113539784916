import React, { useState, useEffect } from "react";
import { ACTION, RISK_LEVEL } from "../../../utils/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addEmailPolicy,
  fetchEmailPolicy,
  updateEmailPolicy
} from "../../../API/policy";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Divider,
  IconButton,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Box,
  Tooltip
} from "@mui/material";
import CustomTabPanel from "../../other/CustomTabPanel";

import { useSelector } from "react-redux";
import "./email-policy-form.css";
import {
  fetchAndFormatClassifications,
  fetchAndFormatFileCategories,
  fetchAndFormatUserGroups,
  convertStringArrayToIntegerArray
} from "../../../utils/Helpers";
import FormShimmering from "../../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import InputList from "../../utility/InputList";
import FormFooter from "../../utility/FormFooter";
import CheckboxSelectorListWithSearch from "../../utility/CheckBoxSelectorListWithSearch";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import InfoIcon from "@mui/icons-material/InfoOutlined";

function EmailPolicyForm({
  setFeedback,
  setOpen,
  open,
  selectedPolicyId,
  setFetchCount
}) {
  const DOMAIN_LIST = "Domain List";
  const FILE_ATTACHMENTS = "File Attachments";
  const CLASSIFICATION_LIST = "Select Classifications";
  const NONE = "NONE";
  const BLOCK = "BLOCK";
  const ALLOW = "ALLOW";
  const RESTRICTION_TYPES = {
    DOMAINS: "DOMAINS",
    ATTACHMENTS: "ATTACHMENTS"
  };

  const [classificationIds, setClassificationIds] = useState([]);
  const [userGroupIds, setUserGroupIds] = useState([]);
  const [showList, setShowList] = useState("");
  const [domainList, setDomainList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [domainRestrictionAction, setDomainRestrictionAction] = useState(NONE);
  const [attachementRestrictionAction, setAttachementRestrictionAction] =
    useState(NONE);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  let formType = selectedPolicyId !== null ? "Update" : "Add";

  const destinationId = [2];

  const user = useSelector(({ auth }) => auth.currentUser);

  function resetEmailForm() {
    setDomainRestrictionAction(NONE);
    setDomainList([]);
    setAttachementRestrictionAction(NONE);
    setAttachmentList([]);
    setClassificationIds([]);
    setUserGroupIds([]);
    setTabValue(0);
    setShowList("");
    formik.setTouched({}, false);
  }

  useEffect(() => {
    setLoading(true);
    resetEmailForm();
    if (open && formType === "Update" && selectedPolicyId) {
      fetchEmailPolicy(selectedPolicyId)
        .then((response) => {
          const { id, name, risk, action } = response.data;
          const { checkBody, checkAttachment, checkSubject } =
            response.data.emailPolicy;
          const restrictionList = response.data.emailRestriction;
          formik.setValues({
            id,
            name,
            risk,
            action,
            checkAttachment,
            checkBody,
            checkSubject
          });
          restrictionList?.forEach((restriction) => {
            if (restriction.type == RESTRICTION_TYPES.DOMAINS) {
              setDomainRestrictionAction(restriction.action);
              setDomainList(restriction.list);
            } else if (restriction.type == RESTRICTION_TYPES.ATTACHMENTS) {
              setAttachementRestrictionAction(restriction.action);
              setAttachmentList(restriction.list);
            }
          });
          setClassificationIds(response.data.classificationIds);
          setUserGroupIds(response.data.userGroupIds);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "An error occurred while fetching the policy.",
            state: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      formik.setValues(formik.initialValues);
      setLoading(false);
    }
  }, [formType, selectedPolicyId, open]);

  const validate = Yup.object({
    name: Yup.string()
      .max(40, "Policy name should be 40 characters or less")
      .required("Please enter policy name.")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      risk: RISK_LEVEL.HIGH,
      action: ACTION.BLOCK,
      checkBody: false,
      checkSubject: false,
      checkAttachment: false
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    let promise;
    let restrictions = [];

    if (domainRestrictionAction != NONE) {
      restrictions.push({
        emailPolicyId: selectedPolicyId,
        action: domainRestrictionAction,
        type: "DOMAINS",
        list: domainList
      });
    }
    if (attachementRestrictionAction != NONE) {
      restrictions.push({
        emailPolicyId: selectedPolicyId,
        action: attachementRestrictionAction,
        type: "ATTACHMENTS",
        list: attachmentList
      });
    }
    if (formType === "Add") {
      promise = addEmailPolicy({
        name: values.name,
        risk: values.risk,
        action: values.action,
        destinationIds: destinationId,
        classificationIds: classificationIds,
        userGroupIds: userGroupIds,
        emailPolicy: {
          checkBody: values.checkBody,
          checkSubject: values.checkSubject,
          checkAttachment: values.checkAttachment
        },
        emailRestriction: restrictions
      });
    } else {
      promise = updateEmailPolicy(selectedPolicyId, {
        name: values.name,
        risk: values.risk,
        action: values.action,
        destinationIds: destinationId,
        classificationIds: classificationIds,
        userGroupIds: userGroupIds,
        emailPolicy: {
          policyId: selectedPolicyId,
          checkBody: values.checkBody,
          checkSubject: values.checkSubject,
          checkAttachment: values.checkAttachment
        },
        emailRestriction: restrictions
      });
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Policy ${message}  Successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        const message = formType === "Add" ? "adding" : "updating";
        setFeedback({
          severity: "error",
          message: `There is an issue while ${message} policy.`,
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfigureList = (listType) => {
    setShowList(listType);
  };
  function props(index) {
    return {
      id: `email-tab-${index}`,
      "aria-controls": `email-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    if (newValue == 2) {
      setShowList(CLASSIFICATION_LIST);
    } else {
      setShowList("");
    }
    setTabValue(newValue);
  };

  return (
    <div>
      <div
        className={`drawer-form-wrapper ${open ? "drawer-form-open" : "drawer-form-closed"
          }`}
        style={showList ? { width: "65%" } : {}}
      >
        <Toolbar />
        <br />
        <div className="close-button">
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        {loading ? (
          <FormShimmering />
        ) : (
          <div className="drawer-form-container group-form-container">
            <h5>{formType} Email Policy</h5>
            <Divider />
            <form onSubmit={formik.handleSubmit} className="group-form-container">
              <Grid
                container
                spacing={2}
                className="group-form-container"
                columns={showList ? 24 : 13}
              >
                <Grid item md={13}>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs"
                      >
                        <Tab label="General" {...props(0)} />
                        <Tab label="Restrictions" {...props(1)} />
                        <Tab label="Classification" {...props(2)} />
                        <Tab label="Associate Groups" {...props(3)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                      <div className="helper-text">
                        Specify the policy name, action
                        (outcome when policy is violated), and risk level
                        associated with the policy.
                      </div>
                      <br />
                      <div>
                        <InputLabel
                          htmlFor="policy-name"
                          className="form-input-label"
                          required
                        >
                          Policy Name
                        </InputLabel>
                        <TextField
                          placeholder="Name"
                          size="small"
                          id="policy-name"
                          className="form-textfield"
                          fullWidth
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className="form-input">
                          <InputLabel
                            htmlFor="policy-action"
                            className="form-input-label"
                          >
                            Action
                          </InputLabel>
                          <Select
                            displayEmpty
                            fullWidth
                            size="small"
                            className="form-selectfield"
                            labelId="policy-action"
                            name="action"
                            id="policy-action"
                            value={
                              formik.values.action ? formik.values.action : ""
                            }
                            onChange={formik.handleChange}
                          >
                            <MenuItem value={ACTION.BLOCK}>Block</MenuItem>
                            <MenuItem value={ACTION.NOTIFY}>Notify</MenuItem>
                            <MenuItem value={ACTION.LOG}>Log</MenuItem>
                          </Select>
                        </div>
                        &emsp;
                        <div className="form-input">
                          <InputLabel
                            htmlFor="policy-risk"
                            className="form-input-label"
                          >
                            Risk
                          </InputLabel>
                          <Select
                            displayEmpty
                            fullWidth
                            size="small"
                            className="form-selectfield"
                            labelId="policy-risk"
                            label="Risk"
                            name="risk"
                            id="policy-risk"
                            value={formik.values.risk}
                            onChange={formik.handleChange}
                          >
                            <MenuItem value={RISK_LEVEL.LOW}>Low</MenuItem>
                            <MenuItem value={RISK_LEVEL.MEDIUM}>
                              Medium
                            </MenuItem>
                            <MenuItem value={RISK_LEVEL.HIGH}>High</MenuItem>
                          </Select>
                        </div>
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                      <div>
                        <div className="restriction-heading">
                          <InputLabel className="form-input-label">
                            Domains Restriction
                            <Tooltip
                              title="Manage Email Recipients: Choose to allow or block emails to specific domains."
                              placement="right"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </InputLabel>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<PlaylistAddIcon />}
                            onClick={() => handleConfigureList(DOMAIN_LIST)}
                          >
                            Configure List
                          </Button>
                        </div>
                        <RadioGroup
                          name="domainRestrictionAction"
                          value={domainRestrictionAction}
                          onChange={(event) =>
                            setDomainRestrictionAction(event.target.value)
                          }
                        >
                          <FormControlLabel
                            value={NONE}
                            control={<Radio />}
                            label="No Restriction"
                            onClick={() => handleConfigureList("")}
                          />
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={ALLOW}
                              control={<Radio />}
                              label="Allow Domains"
                              onClick={() => handleConfigureList(DOMAIN_LIST)}
                            />
                          </div>
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={BLOCK}
                              control={<Radio />}
                              label="Block Domains"
                              onClick={() => handleConfigureList(DOMAIN_LIST)}
                            />
                          </div>
                        </RadioGroup>
                      </div>
                      <br />
                      <div>
                        <div className="restriction-heading">
                          <InputLabel className="form-input-label">
                            Attachments Restriction
                            <Tooltip
                              title="Manage email attachments: Allow or block specific file types from being sent."
                              placement="right"
                            >
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </InputLabel>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<PlaylistAddIcon />}
                            onClick={() =>
                              handleConfigureList(FILE_ATTACHMENTS)
                            }
                          >
                            Configure List
                          </Button>
                        </div>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="attachementRestrictionAction"
                          value={attachementRestrictionAction}
                          onChange={(event) =>
                            setAttachementRestrictionAction(event.target.value)
                          }
                        >
                          <FormControlLabel
                            value={NONE}
                            control={<Radio />}
                            label="No Restriction"
                          />
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={ALLOW}
                              control={<Radio />}
                              label="Allow Attachements"
                              onClick={() =>
                                handleConfigureList(FILE_ATTACHMENTS)
                              }
                            />
                          </div>
                          <div className="radio-button-with-link">
                            <FormControlLabel
                              value={BLOCK}
                              control={<Radio />}
                              label="Block Attachements"
                              onClick={() =>
                                handleConfigureList(FILE_ATTACHMENTS)
                              }
                            />
                          </div>
                        </RadioGroup>
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={2}>
                      <div className="helper-text">
                        You can select classification(s) to enforce
                        content-based restrictions within this policy.
                      </div>
                      <br />
                      <h6>Apply Classification On </h6>
                      <div className="checkbox-field">
                        <Checkbox
                          name="checkBody"
                          id="checkBody"
                          checked={formik.values.checkBody}
                          onChange={(e) =>
                            formik.setFieldValue("checkBody", e.target.checked)
                          }
                        />
                        &emsp;
                        <InputLabel htmlFor="checkBody">
                          Email Body
                        </InputLabel>
                      </div>
                      <div className="checkbox-field">
                        <Checkbox
                          name="checkSubject"
                          id="checkSubject"
                          checked={formik.values.checkSubject}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "checkSubject",
                              e.target.checked
                            )
                          }
                        />
                        &emsp;
                        <InputLabel htmlFor="checkSubject">
                          Email Subject
                        </InputLabel>
                      </div>
                      <div className="checkbox-field">
                        <Checkbox
                          name="checkAttachment"
                          id="checkAttachment"
                          checked={formik.values.checkAttachment}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "checkAttachment",
                              e.target.checked
                            )
                          }
                        />
                        &emsp;
                        <InputLabel htmlFor="checkAttachment">
                          Attachments
                        </InputLabel>
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={3}>
                      <div className="helper-text">
                        This policy is applied to the below group(s). You
                        can select groups to apply this policy to them.
                      </div>
                      <br />
                      <CheckboxSelectorListWithSearch
                        heading="Select User Groups"
                        setFeedback={setFeedback}
                        fetchAndFormatFields={fetchAndFormatUserGroups}
                        selectedRecords={userGroupIds}
                        setSelectedRecords={setUserGroupIds}
                        options={{
                          showFilters: true,
                          showSearchBar: true
                        }}
                        maxHeight="300px"
                        value={tabValue}
                      />
                    </CustomTabPanel>
                  </Box>
                </Grid>
                {showList ? (
                  <>
                    <Grid md={1} item sx={{ Height: "100%" }}>
                      <Divider orientation="vertical" sx={{ opacity: 1 }} />
                    </Grid>
                    <Grid item md={10} style={{ paddingTop: "32px" }}>

                      {(() => {
                        switch (showList) {
                          case DOMAIN_LIST:
                            return (
                              <>
                                <h6>{showList}</h6>
                                <InputList
                                  list={domainList}
                                  setList={setDomainList}
                                  placeholder="Enter Domain"
                                  buttonText="Add Domains"
                                />
                              </>
                            );
                          case FILE_ATTACHMENTS:
                            return (
                              <div>
                                <CheckboxSelectorListWithSearch
                                  heading={showList}
                                  setFeedback={setFeedback}
                                  fetchAndFormatFields={
                                    fetchAndFormatFileCategories
                                  }
                                  selectedRecords={convertStringArrayToIntegerArray(
                                    attachmentList
                                  )}
                                  setSelectedRecords={setAttachmentList}
                                  options={{
                                    showFilters: true,
                                    showSearchBar: true
                                  }}
                                />
                              </div>
                            );
                          case CLASSIFICATION_LIST:
                            return (
                              <>
                                <CheckboxSelectorListWithSearch
                                  heading={showList}
                                  setFeedback={setFeedback}
                                  fetchAndFormatFields={
                                    fetchAndFormatClassifications
                                  }
                                  selectedRecords={classificationIds}
                                  setSelectedRecords={setClassificationIds}
                                  options={{
                                    showFilters: true,
                                    showSearchBar: true
                                  }}
                                  maxHeight={"350px"}
                                  value={tabValue}
                                />
                              </>
                            );
                          default:
                            return null;
                        }
                      })()}
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <FormFooter
                formType={formType}
                errors={formik.errors}
                touched={formik.touched}
                handleClose={handleClose}
              />
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailPolicyForm;
