const ACTION = {
  BLOCK: "BLOCK",
  NOTIFY: "NOTIFY",
  LOG: "LOG"
};

const RISK_LEVEL = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH"
};

const CRITERIA_TYPE = {
  REGEX: "REGEX",
  KEYWORD: "KEYWORD",
  PREDEFINED_ALGORITHM: "PREDEFINED_ALGORITHM"
};

const APPROVAL_STATUS = {
  EXPIRED: "Expired",
  REVOKED: "Revoked",
  ACTIVE: "Active",
  UPCOMING: "Upcoming"
};

const FEATURES = {
  USB: "USB",
  ETHERNET: "ETHERNET",
  CLIPBOARD: "CLIPBOARD",
  SCREENSHOT: "SCREENSHOT",
  SITE_BLOCKING: "SITE_BLOCKING",
  APP_BLOCKING: "APP_BLOCKING",
  EMAIL_BLOCKING: "EMAIL_BLOCKING",
  PASSWORD_CONFIGURATION: "PASSWORD_CONFIGURATION",
  SMTP_CONFIGURATION: "SMTP_CONFIGURATION",
  ACTIVE_DIRECTORY: "ACTIVE_DIRECTORY",
  CLASSIFICATION: "CLASSIFICATION",
  USERS: "USERS",
  DEVICES: "DEVICES",
  GENERAL: "GENERAL"
};

const EMAIL_TEMPLATE_NAMES = {
  ACCESS_APPROVAL_REQUESTED: "Access Approval Request Email",
  ACCESS_APPROVED_TO_ADMIN: "Access Approved (Admin) Email",
  ACCESS_APPROVED_TO_USER: "Access Approved (User) Email",
  ACCESS_DENIED_TO_ADMIN: "Access Denied (Admin) Email",
  ACCESS_DENIED_TO_USER: "Access Denied (User) Email",
  ACCESS_REVOKED_TO_ADMIN: "Access Revoked (Admin) Email",
  ACCESS_REVOKED_TO_USER: "Access Revoked (User) Email",
  POLICY_BREACHED_EMAIL: "Policy Breach Email",
  PASSWORD_RESET_EMAIL: "Password Reset Email"
};

const DIALOG_TYPES = {
  ALERT: "ALERT",
  CONFIRM: "CONFIRM",
  INPUT: "INPUT"
};

const DEVICE_TYPE = {
  BYOD: "BYOD",
  COMPANY_OWNED: "COMPANY_OWNED"
};

const MESSAGES = {
  LOGOUT_MESSAGE: "You have been logged out. Please log in again to continue !",
  GENERIC_EXCEPTION_MESSAGE: "Something went wrong"
};

const DESTINATION_ID_TO_NAME = {
  1: "USB",
  2: "EMAIL",
  3: "HTTP",
  6: "DESKTOP APPS",
  7: "ETHERNET"
};

export {
  ACTION,
  RISK_LEVEL,
  CRITERIA_TYPE,
  APPROVAL_STATUS,
  FEATURES,
  EMAIL_TEMPLATE_NAMES,
  DIALOG_TYPES,
  DEVICE_TYPE,
  MESSAGES,
  DESTINATION_ID_TO_NAME
};
