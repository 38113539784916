import React, { useState, useEffect } from "react";
import { fetchApplications } from "../../../API/devices";
import TableComponent from "../../utility/Table";

export default function DeviceInstalledApplicationTab({deviceId,setFeedback}) {
  const [applicationList, setApplicationList] = useState([]);
  useEffect(() => {
    fetchApplications(deviceId)
      .then(({ data }) => {
        setApplicationList([...data.allowedApps, ...data.blockedApps]);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching the application list.",
          state: true
        });
      });
  }, [deviceId]);

  return <TableComponent headings={["appName"]} data={applicationList} />;
}
