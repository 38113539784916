import GroupsIcon from "../components/assets/icons/GroupsIcon";
import DashboardIcon from "../components/assets/icons/DashboardIcon";
import UserIcon from "../components/assets/icons/UserIcon";
import DesktopWithMobileIcon from "../components/assets/icons/DesktopWithMobileIcon";
import ClassificationIcon from "../components/assets/icons/ClassificationIcon";
import RuleDictionaryIcon from "../components/assets/icons/RuleDictionaryIcon";
import ApprovalIcon from "../components/assets/icons/ApprovalIcon";
import PasswordConfigurationIcon from "../components/assets/icons/PasswordConfigurationIcon";
import UsbIcon from "../components/assets/icons/UsbIcon";
import EmailIcon from "../components/assets/icons/EmailIcon";
import ClipboardIcon from "../components/assets/icons/ClipboardIcon";
import ScreenshotIcon from "../components/assets/icons/ScreenShotIcon";
import SiteBlocking from "../components/assets/icons/SiteBlockingIcon";
import EmailReports from "../components/assets/icons/EmailReportsIcon";
import DeviceReports from "../components/assets/icons/DeviceReportsIcon";
import DeviceControl from "../components/assets/icons/DeviceControlIcon";
import PolicyIcon from "../components/assets/icons/PolicyIcon";
import CategoriesIcon from "../components/assets/icons/CategoriesIcon";
import ActiveDirectoryIcon from "../components/assets/icons/ActiveDirectoryIcon";
import ApplicationIcon from "../components/assets/icons/ApplicationIcon";
import ConfigurationIcon from "../components/assets/icons/ConfigurationIcon";
import { FEATURES } from "./Constants";
import NotifyIcon from "../components/assets/icons/NotifyIcon";
import UsbReportIcon from "../components/assets/icons/UsbReportIcon";
import HttpReportIcon from "../components/assets/icons/HttpReportIcon";
import DesktopAppIcon from "../components/assets/icons/DesktopAppIcon";
import EthernetIcon from "../components/assets/icons/EthernetIcon";

const drawerItems = [
  {
    itemName: "Dashboard",
    itemLink: "/dashboard",
    ItemIcon: DashboardIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "Manage Devices",
    ItemIcon: DeviceControl,
    subItems: [
      {
        itemName: "Devices",
        itemLink: "/view-devices",
        ItemIcon: DesktopWithMobileIcon,
        featureName: FEATURES.DEVICES
      },
      {
        itemName: "Device Groups",
        itemLink: "/device-groups",
        ItemIcon: GroupsIcon,
        featureName: FEATURES.DEVICES
      }
    ]
  },
  {
    itemName: "Device Policies",
    ItemIcon: PolicyIcon,
    subItems: [
      {
        itemName: "USB",
        itemLink: "/usb-policy",
        ItemIcon: UsbIcon,
        featureName: FEATURES.USB
      },
      {
        itemName: "Ethernet",
        itemLink: "/ethernet-policy",
        ItemIcon: UsbIcon,
        featureName: FEATURES.ETHERNET
      },
      // {
      //   itemName: "Clipboard",
      //   itemLink: "/clipboard-policy",
      //   ItemIcon: ClipboardIcon,
      //   featureName: FEATURES.CLIPBOARD
      // },
      // {
      //   itemName: "Screenshot",
      //   itemLink: "/screenshot-policy",
      //   ItemIcon: ScreenshotIcon,
      //   featureName: FEATURES.SCREENSHOT
      // },
      {
        itemName: "HTTP / HTTPs",
        itemLink: "/http-policy",
        ItemIcon: SiteBlocking,
        featureName: FEATURES.SITE_BLOCKING
      },
      {
        itemName: "Desktop apps",
        itemLink: "/app-blocking-policy",
        ItemIcon: PolicyIcon,
        featureName: FEATURES.APP_BLOCKING
      }
    ]
  },
  {
    itemName: "Manage Users",
    ItemIcon: UserIcon,
    subItems: [
      {
        itemName: "Users",
        itemLink: "/view-users",
        ItemIcon: UserIcon,
        featureName: FEATURES.USERS
      },
      {
        itemName: "User Groups",
        itemLink: "/user-groups",
        ItemIcon: DesktopWithMobileIcon,
        featureName: FEATURES.USERS
      }
    ]
  },
  {
    itemName: "Email Policy",
    itemLink: "/email-policy",
    ItemIcon: EmailIcon,
    featureName: FEATURES.EMAIL_BLOCKING
  },
  {
    itemName: "Notifications",
    itemLink: "/policy-breach-alert",
    ItemIcon: NotifyIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "Data Classification",
    ItemIcon: ClassificationIcon,
    subItems: [
      {
        itemName: "Classification",
        itemLink: "/classification-list",
        ItemIcon: ClassificationIcon,
        featureName: FEATURES.CLASSIFICATION
      },
      {
        itemName: "Rule Dictionary",
        itemLink: "/rule-list",
        ItemIcon: RuleDictionaryIcon,
        featureName: FEATURES.CLASSIFICATION
      }
    ]
  },
  // {
  //   itemName: "Configurations",
  //   ItemIcon: ConfigurationIcon,
  //   subItems: [
  //     {
  //       itemName: "Password Configuration",
  //       itemLink: "/password-configuration",
  //       ItemIcon: PasswordConfigurationIcon,
  //       featureName: FEATURES.PASSWORD_CONFIGURATION
  //     },
  //     {
  //       itemName: "SMTP Configurations",
  //       itemLink: "/smtp-settings",
  //       ItemIcon: EmailIcon,
  //       featureName: FEATURES.SMTP_CONFIGURATION
  //     },
  //     {
  //       itemName: "Active Directory",
  //       itemLink: "/ad-details",
  //       ItemIcon: ActiveDirectoryIcon,
  //       featureName: FEATURES.ACTIVE_DIRECTORY
  //     }
  //   ]
  // },
  {
    itemName: "Approval Requests",
    itemLink: "/approval-requests",
    ItemIcon: ApprovalIcon,
    featureName: FEATURES.GENERAL
  },
  // {
  //   itemName: "App Category",
  //   itemLink: "/app-categories",
  //   ItemIcon: ApplicationIcon,
  //   featureName: FEATURES.APP_BLOCKING
  // },
  {
    itemName: "Organizational Domains",
    itemLink: "/organizational-domains",
    ItemIcon: GroupsIcon,
    featureName: FEATURES.GENERAL
  },
];
const reportingDrawerItems = [
  {
    itemName: "USB Reporting",
    itemLink: "/reporting/usb-reporting",
    ItemIcon: UsbReportIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "HTTP Reporting",
    itemLink: "/reporting/http-reporting",
    ItemIcon: HttpReportIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "Desktop Apps Reporting",
    itemLink: "/reporting/desktop-apps-reporting",
    ItemIcon: DesktopAppIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "Ethernet Reporting",
    itemLink: "/reporting/ethernet-reporting",
    ItemIcon: EthernetIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "Email Reports",
    itemLink: "/reporting/email-reports",
    ItemIcon: EmailReports,
    featureName: FEATURES.EMAIL_BLOCKING
  }
];
const routeHeadings = {
  "/view-users": "Users",
  "/groups": "Groups",
  "/view-devices": "Devices",
  "/classification-list": "Classification",
  "/rule-list": "Rule Dictionary",
  "/policy-list": "Policy",
  "/dashboard": "Dashboard",
  "/password-configuration": "Password Configuration",
  "/usb-policy": "USB Policy",
  "/email-policy": "Email Policy",
  "/screenshot-policy": "Screenshot Policy",
  "/clipboard-policy": "Clipboard Policy",
  "/http-policy": "HTTP / HTTPs Policy",
  "/app-blocking-policy": "App Blocking Policy",
  "/device-reports": "Device Reports",
  "/my-account": "Profile",
  "/user-groups": "User Groups",
  "/device-groups": "Device Groups",
  "/app-categories": "App Blocking Categories",
  "/approval-requests": "Time Bound Approvals",
  "/ad-details": "Active Directory",
  "/smtp-settings": "SMTP Configurations",
  "/settings": "Settings",
  "/edit-email-template": "Email Template Configuration",
  "/ethernet-policy": "Ethernet Policy",
  "/organizational-domains": "Organizational Domains",
  "/policy-breach-alert":"Policy Breach Notifications",
  "/reporting/usb-reporting" : "USB Reporting",
  "/reporting/http-reporting" : "HTTP Reporting",
  "/reporting/desktop-apps-reporting" : "Desktop Apps Reporting",
  "/reporting/ethernet-reporting" : "Ethernet Reporting",
  "/reporting/email-reports" : "Email Reporting"
};

export { drawerItems, routeHeadings, reportingDrawerItems };
