import React, { useState, useEffect } from "react";
import AssignFieldsDropdown from "../utility/AssignFieldsDropdown";
import { ACTION, RISK_LEVEL } from "../../utils/Constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addPolicy, fetchPolicy, updatePolicy } from "../../API/policy";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Divider,
  IconButton,
  Stack
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./policy-form.css";
import {
  fetchAndFormatClassifications,
  fetchAndFormatFileCategories,
  fetchAndFormatUserGroups,
  setCurrentClassificationsForPolicy,
  setCurrentFileCategoriesForPolicy,
  setCurrentUserGroupsForPolicy,
  fetchAndFormatDeviceGroups,
  setCurrentDeviceGroupsForPolicy,
  fetchAndFormatSiteBlockingCategory,
  fetchAndFormatAppBlockingCategory,
  setCurrentSiteBlockingCategoryForPolicy,
  setCurrentAppBlockingCategoryForPolicy,
  fetchAndFormatUsbList,
  setCurrentWhitelistedUsbs
} from "../../utils/Helpers";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import AddIcon from "@mui/icons-material/Add";

function PolicyForm({
  setFeedback,
  setOpen,
  open,
  selectedPolicyId,
  setFetchCount
}) {
  const [fileCategoryIds, setFileCategoryIds] = useState([]);
  const [classificationIds, setClassificationIds] = useState([]);
  const [userGroupIds, setUserGroupIds] = useState([]);
  const [deviceGroupIds, setDeviceGroupIds] = useState([]);
  const [siteBlockingCategoryIds, setSiteBlockingCategoryIds] = useState([]);
  const [whitelistedUsbIds, setWhiteListedUsbIds] = useState([]);
  const [appBlockingCategoryIds, setAppBlockingCategoryIds] = useState([]);
  const [showUserGroupDropdown, setShowUserGroupDropdown] = useState(true);
  const [showDeviceGroupDropdown, setShowDeviceGroupDropdown] = useState(true);
  const [loading, setLoading] = useState(true);
  let formType = "Add";
  const location = useLocation();

  let isUsbDestination = location.pathname == "/usb-policy" ? true : false;
  let isEmailDestination = location.pathname == "/email-policy" ? true : false;
  let isClipboardDestination =
    location.pathname == "/clipboard-policy" ? true : false;
  let isScreenshotDestination =
    location.pathname == "/screenshot-policy" ? true : false;
  let isHttpDestination =
    location.pathname == "/http-policy" ? true : false;
  let isAppDestination =
    location.pathname == "/app-blocking-policy" ? true : false;
  let isEthernetDestination =
    location.pathname == "/ethernet-policy" ? true : false;

  let destinationId = [1];
  if (isUsbDestination) destinationId = [1];
  else if (isEmailDestination) destinationId = [2];
  else if (isClipboardDestination) destinationId = [4];
  else if (isScreenshotDestination) destinationId = [5];
  else if (isHttpDestination) destinationId = [3];
  else if (isAppDestination) destinationId = [6];
  else if (isEthernetDestination) destinationId = [7];

  if (selectedPolicyId !== null) formType = "Update";

  const user = useSelector(({ auth }) => auth.currentUser);

  useEffect(() => {
    setLoading(true);
    if (open && formType === "Update" && selectedPolicyId) {
      fetchPolicy(selectedPolicyId)
        .then((response) => {
          const { id, name, risk, action } = response.data;
          formik.setValues({
            id,
            name,
            risk,
            action
          });
          setClassificationIds(response.data.classificationIds);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "An error occurred while fetching the policy.",
            state: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      formik.setValues(formik.initialValues);
      formik.setTouched({}, false);
      setLoading(false);
    }
  }, [formType, selectedPolicyId, open]);

  const validate = Yup.object({
    name: Yup.string()
      .max(40, "Must be 40 characters or less")
      .required("Required")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      risk: RISK_LEVEL.HIGH,
      action: ACTION.BLOCK
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    let promise;
    if (formType === "Add") {
      promise = addPolicy({
        ...values,
        fileCategoryIds: fileCategoryIds,
        destinationIds: destinationId,
        classificationIds: classificationIds,
        userGroupIds: userGroupIds,
        deviceGroupIds: deviceGroupIds,
        siteBlockingCategoryIds: siteBlockingCategoryIds,
        appBlockingCategoryIds: appBlockingCategoryIds,
        usbIds: whitelistedUsbIds
      });
    } else {
      promise = updatePolicy(selectedPolicyId, {
        ...values,
        fileCategoryIds: fileCategoryIds,
        destinationIds: destinationId,
        classificationIds: classificationIds,
        userGroupIds: userGroupIds,
        deviceGroupIds: deviceGroupIds,
        siteBlockingCategoryIds: siteBlockingCategoryIds,
        appBlockingCategoryIds: appBlockingCategoryIds,
        usbIds: whitelistedUsbIds
      });
    }

    promise
      .then((response) => {
        const message = formType === "Add" ? "added" : "updated";
        setFeedback({
          severity: "success",
          message: `Policy ${message}  Successfully`,
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: `Something Went Wrong`,
          state: true
        });
      });
  }

  const deviceGroupIdsHandler = (selectedIds) => {
    setDeviceGroupIds(selectedIds);
    formik.setFieldValue("deviceGroupIds", selectedIds);
  };
  const userGroupIdsHandler = (selectedIds) => {
    setUserGroupIds(selectedIds);
    formik.setFieldValue("userGroupIds", selectedIds);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div
        className={`drawer-form-wrapper ${
          open ? "drawer-form-open" : "drawer-form-closed"
        }`}
      >
        <Toolbar />
        <br />
        <div className="close-button">
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="drawer-form-container">
          {loading ? (
            <FormShimmering />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <h5>{formType} Policy</h5>
              <Divider />

              <div className="form-input">
                <InputLabel
                  htmlFor="policy-name"
                  className="form-input-label"
                  required
                >
                  Policy Name
                </InputLabel>
                <TextField
                  placeholder="Name"
                  size="small"
                  id="policy-name"
                  className="form-textfield"
                  fullWidth
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              {(isUsbDestination || isEmailDestination) &&  (
              <div className="form-input">
                <InputLabel
                  htmlFor="policy-action"
                  className="form-input-label"
                >
                  Action
                </InputLabel>
                <Select
                  displayEmpty
                  fullWidth
                  size="small"
                  className="form-selectfield"
                  labelId="policy-action"
                  name="action"
                  id="policy-action"
                  value={formik.values.action ? formik.values.action : ""}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={ACTION.BLOCK}>Block</MenuItem>
                  <MenuItem value={ACTION.NOTIFY}>Notify</MenuItem>
                  <MenuItem value={ACTION.LOG}>Log</MenuItem>
                </Select>
              </div> )}

              <div className="form-input">
                <InputLabel htmlFor="policy-risk" className="form-input-label">
                  Risk
                </InputLabel>
                <Select
                  displayEmpty
                  fullWidth
                  size="small"
                  className="form-selectfield"
                  labelId="policy-risk"
                  label="Risk"
                  name="risk"
                  id="policy-risk"
                  value={formik.values.risk}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={RISK_LEVEL.LOW}>Low</MenuItem>
                  <MenuItem value={RISK_LEVEL.MEDIUM}>Medium</MenuItem>
                  <MenuItem value={RISK_LEVEL.HIGH}>High</MenuItem>
                </Select>
              </div>

              {!isUsbDestination &&
                !isEthernetDestination &&
                !isScreenshotDestination &&
                !isAppDestination && (
                  <div className="form-input">
                    <InputLabel className="form-input-label">
                      File Extensions
                    </InputLabel>
                    <AssignFieldsDropdown
                      open={open}
                      setFieldIds={setFileCategoryIds}
                      fieldPlaceholder="File Extensions"
                      fetchAndFormatFields={fetchAndFormatFileCategories}
                      onSearch={false}
                      setCurrentFields={setCurrentFileCategoriesForPolicy}
                      parentId={selectedPolicyId}
                      formType={formType}
                    />
                  </div>
                )}
              {!isUsbDestination &&
                !isEthernetDestination &&
                !isClipboardDestination &&
                !isScreenshotDestination &&
                !isHttpDestination &&
                !isAppDestination && (
                  <div className="form-input">
                    <InputLabel className="form-input-label">
                      User Group
                    </InputLabel>
                    <AssignFieldsDropdown
                      open={open}
                      setFieldIds={userGroupIdsHandler}
                      fieldPlaceholder="User Group"
                      fetchAndFormatFields={fetchAndFormatUserGroups}
                      onSearch={false}
                      setCurrentFields={setCurrentUserGroupsForPolicy}
                      parentId={selectedPolicyId}
                      formType={formType}
                    />
                  </div>
                )}
              {!isEmailDestination && (
                <div className="form-input">
                  <InputLabel className="form-input-label">
                    Device Group
                  </InputLabel>
                  <AssignFieldsDropdown
                    open={open}
                    setFieldIds={deviceGroupIdsHandler}
                    fieldPlaceholder="Device Group"
                    fetchAndFormatFields={fetchAndFormatDeviceGroups}
                    onSearch={false}
                    setCurrentFields={setCurrentDeviceGroupsForPolicy}
                    parentId={selectedPolicyId}
                    formType={formType}
                  />
                </div>
              )}
              {!isUsbDestination &&
                !isEthernetDestination &&
                !isScreenshotDestination &&
                !isAppDestination && (
                  <div className="form-input">
                    <InputLabel className="form-input-label">
                      Classification
                    </InputLabel>
                    <AssignFieldsDropdown
                      open={open}
                      customerId={user.userId}
                      setFieldIds={setClassificationIds}
                      fieldPlaceholder="Classification"
                      fetchAndFormatFields={fetchAndFormatClassifications}
                      onSearch={false}
                      setCurrentFields={setCurrentClassificationsForPolicy}
                      parentId={selectedPolicyId}
                      formType={formType}
                    />
                  </div>
                )}
              {isHttpDestination && (
                <div className="form-input">
                  <InputLabel className="form-input-label">
                    Select domain categories for whitelisting
                  </InputLabel>
                  <AssignFieldsDropdown
                    open={open}
                    setFieldIds={setSiteBlockingCategoryIds}
                    fieldPlaceholder="Select domain categories for whitelisting"
                    fetchAndFormatFields={fetchAndFormatSiteBlockingCategory}
                    onSearch={false}
                    setCurrentFields={setCurrentSiteBlockingCategoryForPolicy}
                    parentId={selectedPolicyId}
                    formType={formType}
                  />
                </div>
              )}
              {isUsbDestination && (
                <div className="form-input">
                  <InputLabel className="form-input-label">
                    Select usbs for whitelisting
                  </InputLabel>
                  <AssignFieldsDropdown
                    open={open}
                    setFieldIds={setWhiteListedUsbIds}
                    fieldPlaceholder="Select usbs for whitelisting"
                    fetchAndFormatFields={fetchAndFormatUsbList}
                    onSearch={false}
                    setCurrentFields={setCurrentWhitelistedUsbs}
                    parentId={selectedPolicyId}
                    formType={formType}
                  />
                </div>
              )}
              {isAppDestination && (
                <div className="form-input">
                  <InputLabel className="form-input-label">
                    App Blocking Category
                  </InputLabel>
                  <AssignFieldsDropdown
                    open={open}
                    setFieldIds={setAppBlockingCategoryIds}
                    fieldPlaceholder="App Blocking Category"
                    fetchAndFormatFields={fetchAndFormatAppBlockingCategory}
                    onSearch={false}
                    setCurrentFields={setCurrentAppBlockingCategoryForPolicy}
                    parentId={selectedPolicyId}
                    formType={formType}
                  />
                </div>
              )}
              <br />
              <Stack spacing={2} direction="row" justifyContent="flex-end">
                <Button
                  className="primary-button-outlined"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-button-filled"
                  variant="contained"
                  type="submit"
                  startIcon={
                    formType == "Update" ? (
                      <BeenhereOutlinedIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                >
                  {formType}
                </Button>
              </Stack>
              <br />
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default PolicyForm;
