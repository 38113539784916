import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useRef } from "react";

function AssignFieldsDropdown({
  customerId,
  open,
  fieldIds,
  setFieldIds,
  fieldLabel,
  fieldPlaceholder,
  fetchAndFormatFields,
  setCurrentFields,
  parentId,
  autoUpdateField,
  fieldList,
  onSearch,
  formType
}) {
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [stoppedTyping, setStoppedTyping] = useState(false);
  const firstRender = useRef(true);
  const lastChanged = useRef(Date.now());

  function fetchFields() {
    if (
      onSearch === false ||
      (inputValue.length > 0 && Date.now() - 500 >= lastChanged.current)
    ) {
      setStoppedTyping(true);
      setData([]);
      fetchAndFormatFields(inputValue)
        .then((response) => {
          if (response) {
            setData(response);
          }
          setStoppedTyping(false);
        })
        .catch((error) => {
          console.log(error.data);
        });
    }
  }

  useEffect(() => {
    if (setCurrentFields != undefined && formType === "Update") {
      if (fieldList !== undefined) {
        setCurrentFields(fieldList)
          .then((data) => {
            setFieldIds(data.map((field) => field.fieldId));
            setSelectedOptions(data);
          })
          .catch((error) => console.log(error));
      } else if (fieldIds !== undefined) {
        setCurrentFields(fieldIds)
          .then((data) => {
            setSelectedOptions(data);
          })
          .catch((error) => console.log(error));
      } else
        setCurrentFields(parentId)
          .then((data) => {
            setFieldIds(data.map((field) => field.fieldId));
            setSelectedOptions(data);
          })
          .catch((error) => console.log(error));
    } else {
      setSelectedOptions([]);
    }
  }, [fieldIds, fieldList, parentId, open]);

  useEffect(() => {
    if (firstRender.current) {
      if (autoUpdateField !== undefined)
        autoUpdateField(parentId, fieldIds).then((response) => {
          console.log(response.data);
        });
    }
  }, [fieldIds]);

  const handleChange = (event, newInputValue) => {
    var tempFieldIds = [];

    tempFieldIds = newInputValue.map((item) => {
      return item.fieldId;
    });
    setFieldIds(tempFieldIds);
  };

  useEffect(() => {
    setTimeout(fetchFields, 500);
  }, [inputValue]);

  return (
    <div>
      <Autocomplete
        multiple
        disableCloseOnSelect
        size="small"
        id="asynchronous-demo"
        loading={stoppedTyping}
        options={[
          ...selectedOptions,
          ...data.filter((item) => {
            if (
              !selectedOptions.some(
                (field) => field.fieldValue === item.fieldValue
              )
            ) {
              return true;
            }
          })
        ]}
        getOptionLabel={(option) => option.fieldValue}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={fieldPlaceholder}
            label={fieldLabel}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {stoppedTyping ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          lastChanged.current = Date.now();
        }}
        onChange={(event, newValue) => {
          handleChange(event, newValue);
          setSelectedOptions(newValue);
        }}
        value={selectedOptions}
        // noOptionsText={inputValue === "" ? "Start Typing" : "No Search found"}
      />
    </div>
  );
}

export default AssignFieldsDropdown;
