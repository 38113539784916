import React, { useEffect, useState } from "react";
import TableComponent from "../utility/Table";
import { fetchDevicesWithPagination } from "../../API/devices";
import "./device-list.css";
import PageTitle from "../utility/PageTitle";
import MultiFilter from "../utility/MultiFilter";
import BulkAction from "../utility/BulkAction";
import {
  fetchAndFormatDeviceGroups,
  setCurrentDeviceGroupsForPolicy,
  fetchAndFormatAgentVersion
} from "../../utils/Helpers";
import { useLocation, useNavigate } from "react-router-dom";
import RemoteLockWithConfiramtion from "./device_actions/RemoteLockWithConfirmation";
import AgentDownloadButton from "./AgentDownloadButton";
import RedCircle from "../assets/icons/RedCircle";
import GreenCircle from "../assets/icons/GreenCircle";
import Pagination from "../utility/Pagination";

function DevicesListPage({ setFeedback }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [devices, setDevices] = useState(null);
  const [selectedRecordId, setSelectedRecordId] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const STATUS_ACTIVE = "Active";
  const STATUS_INACTIVE = "Inactive";
  const [filterValuesList, setFilterValuesList] = useState({
    Version: [],
    Status: [
      { filterValue: STATUS_ACTIVE, filterId: STATUS_ACTIVE },
      { filterValue: STATUS_INACTIVE, filterId: STATUS_INACTIVE }
    ]
  });
  const [filtersSelectedValue, setFiltersSelectedValue] = useState({
    Version: "all",
    Status: state ? (state.isActive ? "Active" : "Inactive") : "all"
  });
  const [bulkActions, setBulkActions] = useState(["Assign To Group"]);
  const [isRemoteLockConfirmationOpen, setIsRemoteLockConfirmationOpen] =
    useState(false);

  function findStatus(date) {
    date = new Date(date);
    let currentDate = new Date();
    if (currentDate - date > 2 * 60000) {
      return "Inactive";
    } else return "Active";
  }
  useEffect(()=>{
    fetchAndFormatAgentVersion().
    then((data)=>{
      setFilterValuesList((prevState) => ({
        ...prevState,
        Version: data
      }));
    })
    .catch((error)=>{
      setFeedback({
        severity: "error",
        message: error.response.data,
        state: true
      });
    })
  },[])

  useEffect(() => {
    fetchDevicesWithPagination(
      pageSize,
      pageNumber,
      searchText,
      filtersSelectedValue.Version,
      filtersSelectedValue.Status
    )
      .then(({ data }) => {
        let deviceList = data.deviceList;
        setTotalCount(data.totalCount);
        deviceList = deviceList.map((item) => {
          return {
            ...item,
            status: findStatus(item.lastResponse + "Z"),
            isEnabled: item.isPasswordChangePolicyApplied
          };
        });
        setDevices(deviceList);
      })
      .catch((error) => console.log(error));
  }, [pageNumber, filtersSelectedValue, searchText, pageSize]);

  const handleRemoteLockConfirm = (id) => {
    setSelectedId(id);
    setIsRemoteLockConfirmationOpen(true);
  };

  const iconStyles = {
    marginLeft: "4px",
    height: "12px",
    width: "12px",
    padding: "1px"
  };
  const chipsStyles = {
    status: {
      Active: {
        icon: <GreenCircle style={iconStyles} />,
        style: {
          color: "#15803D",
          backgroundColor: "#DCFCE7",
          fontWeight: "bold",
          borderRadius: "5px"
        }
      },
      Inactive: {
        icon: <RedCircle style={iconStyles} />,
        style: {
          color: "#B91C1C",
          backgroundColor: "#FEE2DC",
          fontWeight: "bold",
          borderRadius: "5px"
        }
      }
    }
  };

  const handleOpenDeviceProfile = (deviceId) => {
    navigate(`?id=${deviceId}`);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <PageTitle subheading="Manage and Monitor Your Organization's Devices" />
        <AgentDownloadButton setFeedback={setFeedback} />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          justifyContent: "space-between"
        }}
      >
        <MultiFilter
          filterValuesList={filterValuesList}
          filtersSelectedValue={filtersSelectedValue}
          setFiltersSelectedValue={setFiltersSelectedValue}
          showSearchBar={true}
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder="Search by ID"
          styles={{display:'flex'}}
        />
      </div>
      <div>
        <BulkAction
          bulkActions={bulkActions}
          fetchAndFormatFields={fetchAndFormatDeviceGroups}
          fieldPlaceholder={"Device Group"}
          fieldLabel={"Device Group"}
          onSearch={false}
          setCurrentFields={setCurrentDeviceGroupsForPolicy}
          selectedRecordId={selectedRecordId}
          setFeedback={setFeedback}
        />
      </div>
      <TableComponent
        headings={[
          "id",
          "deviceName",
          "displayName",
          "deviceGroups",
          "operatingSystem",
          "agentVersion",
          "status"
        ]}
        options={{ hideIds: true, maxChips: 2 }}
        data={devices}
        chipsStyles={chipsStyles}
        styles={{
          status: "chip",
          deviceName: "button",
          table: { maxHeight: 425 }
        }}
        actions={[
          {
            label: "Remote Lock",
            onClickHandler: handleRemoteLockConfirm,
            hasParameter: true
          }
        ]}
        multiselect={{
          selectedRecordId,
          setSelectedRecordId
        }}
        columnWidths={{
          operatingSystem: 250,
          displayName: 130,
          deviceName: 130
        }}
        customColumnNames={{
          id: "Device ID",
          IsEnabled: "Password Policy"
        }}
        buttonActions={{
          deviceName: { onClickHandler: handleOpenDeviceProfile }
        }}
      />
      <RemoteLockWithConfiramtion
        confirmRemoteLock={isRemoteLockConfirmationOpen}
        setConfirmRemoteLock={setIsRemoteLockConfirmationOpen}
        deviceId={selectedId}
        setFeedback={setFeedback}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
    </div>
  );
}

export default DevicesListPage;
